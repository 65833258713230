<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="3"/>

    <!-- <div class="row"> -->
      <!-- <div class="col-12 contentDiv">  -->
      <div class="contentDiv"> 
        <div class="row">
          <!-- <span class="toolboxBtn" @click="toggleToolbox()">
            <fa :icon="toolboxBtnIcon" class="text-white" />
          </span> -->

          <span v-if="!showToolbox" class="toolboxBtnClose" @click="toggleToolbox">
            <fa icon="chevron-right" class="text-white" />
          </span>

          <span v-if="showToolbox" class="toolboxBtnOpen" @click="toggleToolbox">
            <fa icon="chevron-left" class="text-white" style="padding-left: 2px;" />
          </span>

          <!-- <div class="col-5 col-md-3 col-lg-2 bottom-0 p-0 vh-100 toolbar" v-if="showToolbox" style="padding-top: 30px; z-index: 2"> ORIG -->
          <div class="col-5 col-md-3 col-lg-2 bottom-0 p-0 vh-100 toolbarFloat" v-if="showToolbox" style="z-index: 2">
            <!-- Toolbox -->
            <ul class="list-group rounded-0" >
              <li class="list-group-item small border-0 pb-0 mb-0">
                 <!-- Big Screen -->
                  <table width="100%" class="d-none d-md-block">
                    <tr>
                      <td width="20px">&nbsp;</td>
                      <td v-if="page === 1" class="pe-2 text-secondary"><fa icon="angle-double-left" :disabled="page === 1"></fa></td>
                      <td v-if="page !== 1" class="pe-2 isLink" @click="navigatePage('first'); updPageNum(page)" ><fa icon="angle-double-left"></fa></td>
                      <td v-if="page <= 1" class="px-2 text-secondary"><fa icon="angle-left"></fa></td>
                      <td v-if="page > 1" class="px-2 isLink" @click="navigatePage('prev'); updPageNum(page)"><fa icon="angle-left"></fa></td>
                      <td><input type="text" v-model="goToPage" class="form-control form-control-sm" @input="navigatePage(); updPageNum(page)" style="width: 40px" ></td>
                      <td class="px-2">/ {{ pageCount }}</td>
                      <td v-if="page >= pageCount" class="px-2 text-secondary"><fa icon="angle-right"></fa></td>
                      <td v-if="page < pageCount" class="px-2 isLink" @click="navigatePage('next'); updPageNum(page)"><fa icon="angle-right"></fa></td>
                      <td v-if="page === pageCount" class="ps-2 text-secondary"><fa icon="angle-double-right" ></fa></td>
                      <td v-if="page !== pageCount" class="ps-2 isLink" @click="navigatePage('last'); updPageNum(page)"><fa icon="angle-double-right"></fa></td>
                    </tr>
                  </table>
                  <!-- Big Screen End -->

                  <!-- Small Screen -->
                  <div class="d-block d-md-none">
                    <div class="row mb-2">
                      <div v-if="page === 1" class="col-3 text-secondary">
                        <fa icon="angle-double-left" :disabled="page === 1" />
                      </div>
                      <div v-if="page !== 1" class="col-3 isLink" @click="navigatePage('first'); updPageNum(page)">
                        <fa icon="angle-double-left" />
                      </div>

                      <div v-if="page <= 1" class="col-3 text-secondary">
                        <fa icon="angle-left" />
                      </div>
                      <div v-if="page > 1" class="col-3 isLink" @click="navigatePage('prev'); updPageNum(page)">
                        <fa icon="angle-left"/>
                      </div>

                      <div v-if="page >= pageCount" class="col-3 text-secondary">
                        <fa icon="angle-right" />
                      </div>
                      <div v-if="page < pageCount" class="col-3 isLink" @click="navigatePage('next'); updPageNum(page)">
                        <fa icon="angle-right" />
                      </div>

                      <div v-if="page === pageCount" class="col-3 text-secondary">
                        <fa icon="angle-double-right" />
                      </div>
                      <div v-if="page !== pageCount" class="col-3 isLink" @click="navigatePage('last'); updPageNum(page)">
                        <fa icon="angle-double-right" />
                      </div>
                    </div>  

                    <table align="center" border="0">
                      <tr>
                        <td width="60px"><input type="text" v-model="goToPage" class="form-control form-control-sm" @input="navigatePage(); updPageNum(page)" style="width: 50px" ></td>
                        <td>/ {{ pageCount }}</td>
                      </tr>
                    </table>
                  </div>
                  <!-- Small Screen End -->

              </li>
              <li class="px-3 py-0 my-0"><hr /></li>

              <li class="list-group-item small border-0 pt-0 mt-0">
                  <!-- <table width="100%"> -->
                  <table border="0">
                    <tr> 
                      <td v-if="scale === 1" width="50px" class="px-2 text-secondary text-end" ><fa icon="minus"></fa></td>
                      <td v-if="scale !== 1" width="50px" class="px-2 text-end isLink" @click="zoomOut"><fa icon="minus"></fa></td>

                      <td v-if="scale === 1" width="70px" class="pe-2 text-center" @click="zoomDefault">{{Math.floor(scale*100)}}%</td>
                      <td v-else width="70px" class="pe-2 isLink text-center" @click="zoomDefault">{{Math.floor(scale*100)}}%</td>

                      <td width="50px" class="ps-2 isLink pe-1" @click="zoomIn"><fa icon="plus"></fa></td>
                    </tr>
                  </table>
              </li>
              <li v-if="doc !== null" class="list-group-item border-0 mb-0">
                <table width="100%" border="0">
                  <tr>
                    <td><div class="pb-1 text-secondary" style="font-size: 10px">
                      STEP 1 - Select document
                      <span class="ms-1 badge bg-grey" style="font-size: 9px">{{docs.length}}</span>
                      </div></td>
                  </tr>
                  <tr>
                    <td class="ps-2">
                      <span class="isLink text-nowrap">
                        <a href="#" v-if="docs.length > 1" data-bs-toggle="modal" data-bs-target="#mdlDoc">
                          <span class="isLink" :class="signeeSelectedClass + 'Bg'" style="padding: 5px; border-radius: 5px">
                              
                              <DocStatus :status="docStatus" class="" />
                              <Popper class="popperDark" arrow hover :content="'Project: ' + getProjectName">
                                {{ (docName.replace('.pdf', '').length > 22 ? docName.substring(0, 22) + '...' : docName.replace('.pdf', '')) }}
                              </Popper>

                          </span>
                        </a>
                        <span v-else class="isTooltipsLabel">
                            <DocStatus :status="docStatus" class="" />
                            <Popper class="popperDark" arrow hover :content="'Project: ' + getProjectName">
                              {{ (docName.replace('.pdf', '').length > 22 ? docName.substring(0, 22) + '...' : docName.replace('.pdf', '')) }}
                            </Popper>  
                        </span>
                      </span>

                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div class="pt-4 pb-1 text-secondary" style="font-size: 10px">
                        STEP 2 - Select signee <span class="badge bg-grey" style="font-size: 9px">{{docSignees.length}}</span> <span class="text-warning"></span> - {{isWorkflow}} | {{signeeOpt}}
                      </div></td>
                  </tr>

                  <tr>
                    <td class="ps-2">
                      <a v-if="signeeOpt === 'signee'" href="#" data-bs-toggle="modal" data-bs-target="#mdlSignee">
                        <span v-if="signeeName !== null" class="isLink" :class="signeeSelectedClass + 'Bg'" style="padding: 5px; border-radius: 5px">
                          <fa icon="circle-user" size="lg" class="text-grey isLabel" />
                          {{ (signeeName.length > 22 ? signeeName.substring(0, 22) + '...' : signeeName) }}
                        </span>
                      </a>

                      <a v-else href="#" data-bs-toggle="modal" data-bs-target="#mdlSignee">
                        <span v-if="workflowTitle !== null" class="isLink" :class="signeeSelectedClass + 'Bg'" style="padding: 5px; border-radius: 5px">
                          <fa icon="sitemap" size="lg" class="text-grey isLabel" />
                          {{ (workflowTitle.length > 22 ? workflowTitle.substring(0, 22) + '...' : workflowTitle) }}
                        </span>
                        <div style="margin-top: 10px;">
                          <span :class="signeeSelectedClass + 'Bg'" style="padding: 5px; border-radius: 5px">
                            <span class="me-2">STEP {{workflowSigneeStep}}</span>
                            <span v-if="workflowSigneeIsRole" class="badge bg-grey">{{workflowSignee}}</span>
                            <span v-else>{{workflowSignee}}</span>
                          </span>
                        </div>
                      </a>
                      
                      <!-- <span v-if="showDebug" class="text-info" style="font-size: 10px">PDF {{pdfWidth.toFixed(0)}}x{{pdfHeight.toFixed(0)}} | {{viewWidth.toFixed(0)}}x{{viewHeight.toFixed(0)}} uiRatio: {{uiRatio}}</span> -->
                      <!-- <span @click="modify" class="ms-2 isLink">MODIFY {{pdfHeight}}</span> -->
                      <!-- <span style="font-family: Helvetica; font-size: 20.5px;">Name {{ uiRatio}}</span> -->
                    </td>
                  </tr>
                </table>
              </li>

            </ul>
            
            <!-- Sigature field -->
            <div class="card rounded-0 border-0">
              <div class="card-body pb-0">
                  <div class="pb-0 text-secondary" style="font-size: 10px">STEP 3 - Assign signing objects</div>
                  <div class="ps-2 pt-1">Signature Fields</div>
              </div>
              <div>
                <div class="card-body p-0 small">
                  <!-- Clickable   -->
                  <ul v-if="docStatus === 'DRAFT'" class="list-group rounded-0 ps-4">
                    <li class="list-group-item border-0 isLink pt-1 pb-0" :class="signeeSelectedClass" @click="add('fullSignature', winTop)">
                      <fa icon="signature" class="me-2" />Full Signature
                      <span v-if="getFieldPage('fullSignature') !== []" class="badge bg-secondary me-1 isLink" v-for="p in getFieldPage('fullSignature')" :key="p" @click="navigatePage(p); updPageNum(p)">{{ p }}</span>
                    </li>
                    <li class="list-group-item border-0 isLink pb-0" :class="signeeSelectedClass" @click="add('initial', winTop)">
                      <fa icon="pen-nib" class="me-2" />Initial
                      <span v-if="getFieldPage('initial') !== []" class="badge bg-secondary me-1 isLink" v-for="p in getFieldPage('initial')" :key="p" @click="navigatePage(p); updPageNum(p)">{{ p }}</span>
                    </li>
                  </ul>
                  <!-- Read Only -->
                  <ul v-else class="list-group rounded-0">
                    <li class="list-group-item border-0 pt-1 pb-0" :class="signeeSelectedClass">
                      <fa icon="signature" class="me-2" />Full Signature
                      <span v-if="getFieldPage('fullSignature') !== []" class="badge bg-secondary me-1 isLink" v-for="p in getFieldPage('fullSignature')" :key="p" @click="navigatePage(p); updPageNum(p)">{{ p }}</span>
                    </li>
                    <li class="list-group-item border-0 pb-0" :class="signeeSelectedClass">
                      <fa icon="pen-nib" class="me-2" />Initial
                      <span v-if="getFieldPage('initial') !== []" class="badge bg-secondary me-1 isLink" v-for="p in getFieldPage('initial')" :key="p" @click="navigatePage(p); updPageNum(p)">{{ p }}</span>
                    </li>
                  </ul>

                </div>
              </div>
            </div>

            <!-- Auto-fill field -->
            <div class="card rounded-0 border-0">
              <div class="card-body pb-0 pt-2">
                <div class="ps-2 pt-1">Auto-fill Fields</div>
              </div>
              <div>
                <div class="card-body p-0 small">
                  <!-- Clickable -->
                  <ul v-if="docStatus === 'DRAFT'" class="list-group rounded-0 ps-4">
                    <li class="list-group-item border-0 isLink pt-1 pb-0" :class="signeeSelectedClass" @click="add('dateSigned', winTop)">
                      <fa icon="calendar-alt" class="me-2" />Date Signed
                      <span v-if="getFieldPage('dateSigned') !== []" class="badge bg-secondary me-1 isLink" v-for="p in getFieldPage('dateSigned')" :key="p" @click="navigatePage(p); updPageNum(p)">{{ p }}</span>
                    </li>
                    <li class="list-group-item border-0 isLink pb-0" :class="signeeSelectedClass" @click="add('name', winTop)">
                      <fa icon="user-circle" class="me-2" />Name
                      <span v-if="getFieldPage('name') !== []" class="badge bg-secondary me-1 isLink" v-for="p in getFieldPage('name')" :key="p" @click="navigatePage(p); updPageNum(p)">{{ p }}</span>
                    </li>
                    <li class="list-group-item border-0 isLink pb-0" :class="signeeSelectedClass" @click="add('email', winTop)">
                      <fa icon="at" class="me-2" />Email
                      <span v-if="getFieldPage('email') !== []" class="badge bg-secondary me-1 isLink" v-for="p in getFieldPage('email')" :key="p" @click="navigatePage(p); updPageNum(p)">{{ p }}</span>
                    </li>
                    <li class="list-group-item border-0 isLink pb-0" :class="signeeSelectedClass" @click="add('idPassport', winTop)">
                      <fa icon="id-card" class="me-2" />ID/Passport
                      <span v-if="getFieldPage('idPassport') !== []" class="badge bg-secondary me-1 isLink" v-for="p in getFieldPage('idPassport')" :key="p" @click="navigatePage(p); updPageNum(p)">{{ p }}</span>
                    </li>
                  </ul>
                  <!-- Read Only -->
                  <ul v-else class="list-group rounded-0">
                    <li class="list-group-item border-0 pt-1 pb-0" :class="signeeSelectedClass">
                      <fa icon="calendar-alt" class="me-2" />Date Signed
                      <span v-if="getFieldPage('dateSigned') !== []" class="badge bg-secondary me-1 isLink" v-for="p in getFieldPage('dateSigned')" :key="p" @click="navigatePage(p); updPageNum(p)">{{ p }}</span>
                    </li>
                    <li class="list-group-item border-0 pb-0" :class="signeeSelectedClass">
                      <fa icon="user-circle" class="me-2" />Name
                      <span v-if="getFieldPage('name') !== []" class="badge bg-secondary me-1 isLink" v-for="p in getFieldPage('name')" :key="p" @click="navigatePage(p); updPageNum(p)">{{ p }}</span>
                    </li>
                    <li class="list-group-item border-0 pb-0" :class="signeeSelectedClass">
                      <fa icon="at" class="me-2" />Email
                      <span v-if="getFieldPage('email') !== []" class="badge bg-secondary me-1 isLink" v-for="p in getFieldPage('email')" :key="p" @click="navigatePage(p); updPageNum(p)">{{ p }}</span>
                    </li>
                    <li class="list-group-item border-0 pb-0" :class="signeeSelectedClass">
                      <fa icon="id-card" class="me-2" />ID/Passport
                      <span v-if="getFieldPage('idPassport') !== []" class="badge bg-secondary me-1 isLink" v-for="p in getFieldPage('idPassport')" :key="p" @click="navigatePage(p); updPageNum(p)">{{ p }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- Standard Field -->
            <div class="card rounded-0 border-0">
              <div class="card-body pb-0 pt-2">
                <div class="ps-2 pt-1">Standard Fields</div>
              </div>
              <div>
                <div class="card-body p-0 small">
                  <!-- Clickable -->
                  <ul v-if="docStatus === 'DRAFT'" class="list-group rounded-0 ps-4">
                    <li class="list-group-item border-0 isLink pt-1 pb-0" :class="signeeSelectedClass" @click="add('textbox', winTop)">
                      <fa icon="font" class="me-2" />Textbox
                      <span v-if="getFieldPage('textbox') !== []" class="badge bg-secondary me-1 isLink" v-for="p in getFieldPage('textbox')" :key="p" @click="navigatePage(p); updPageNum(p)">{{ p }}</span>
                    </li>
                    <li class="list-group-item border-0 isLink pb-0" :class="signeeSelectedClass" @click="add('textarea', winTop)">
                      <fa icon="bars" class="me-2" />Textarea
                      <span v-if="getFieldPage('textarea') !== []" class="badge bg-secondary me-1 isLink" v-for="p in getFieldPage('textarea')" :key="p" @click="navigatePage(p); updPageNum(p)">{{ p }}</span>
                    </li>
                    <li class="list-group-item border-0 isLink pb-0" :class="signeeSelectedClass" @click="add('checkbox', winTop)">
                      <fa icon="check-square" class="me-2" />Checkbox
                      <span v-if="getFieldPage('checkbox') !== []" class="badge bg-secondary me-1 isLink" v-for="p in getFieldPage('checkbox')" :key="p" @click="navigatePage(p); updPageNum(p)">{{ p }}</span>
                    </li>
                  </ul>
                  <!-- Read Only -->
                  <ul v-else class="list-group rounded-0">
                    <li class="list-group-item border-0 pt-1 pb-0" :class="signeeSelectedClass">
                      <fa icon="font" class="me-2" />Textbox
                      <span v-if="getFieldPage('textbox') !== []" class="badge bg-secondary me-1 isLink" v-for="p in getFieldPage('textbox')" :key="p" @click="navigatePage(p); updPageNum(p)">{{ p }}</span>
                    </li>
                    <li class="list-group-item border-0 pb-0" :class="signeeSelectedClass">
                      <fa icon="bars" class="me-2" />Textarea
                      <span v-if="getFieldPage('textarea') !== []" class="badge bg-secondary me-1 isLink" v-for="p in getFieldPage('textarea')" :key="p" @click="navigatePage(p); updPageNum(p)">{{ p }}</span>
                    </li>
                    <li class="list-group-item border-0 pb-0" :class="signeeSelectedClass">
                      <fa icon="check-square" class="me-2" />Checkbox
                      <span v-if="getFieldPage('checkbox') !== []" class="badge bg-secondary me-1 isLink" v-for="p in getFieldPage('checkbox')" :key="p" @click="navigatePage(p); updPageNum(p)">{{ p }}</span>
                    </li>
                  </ul>
                </div>
              </div>

            </div>  

            <div class="ps-3 ">
              <div class="pt-3 pb-0 text-secondary" style="font-size: 10px">STEP 4 - Save document changes</div>
              <div v-if="docStatus === 'DRAFT'" class="isLink text-secondary d-grid pe-2 ps-2 me-2">
                <button @click="saveDoc" type="button" class="btn btn-sm btn-secondary btn-block">
                  <fa icon="floppy-disk" size="lg" class="me-2" />Save
                </button>
              </div>
              <div v-else class="text-secondary d-grid pe-2 ps-2">
                <button type="button" class="btn btn-sm btn-block btn-secondary" disabled><fa icon="floppy-disk" size="lg" class="me-2" />Save</button>
              </div>
            </div>


            <!-- <div v-if="docStatus === 'DRAFT'" class="card rounded-0 border-0 pt-1">
              <button class="btn btn-secondary btn-sm" @click="saveDoc">Save</button>
            </div> -->
          <!-- End of Toolbox -->
          </div>
          <div :class="contentClass">
            <div class="vh-100 gx-0" style="margin-top: 45px">
              <!-- PDF  -->
              <div v-if="!showUIBox" class="p-2">
                  <div class="spinner-border spinner-border-sm me-1"></div> Loading...
              </div>
              <!-- Attribute bar -->
              <div v-if="showAttr" class="p-2">
                <div class="row">
                  
                  <div class="col-sm-12 col-md-6 col-lg-4 py-1">
                    <table width="100%">
                      <tr>
                        <td width="50%">
                          <span v-if="box.type === 'fullSignature'">
                            <fa icon="signature" size="lg" class="me-2" :class="signeeSelectedClass" />
                            <span :class="signeeSelectedClass" style="font-size: 14px">Full Signature</span>
                          </span>

                          <span v-if="box.type === 'initial'" >
                            <fa icon="pen-nib" size="lg" class="me-1" :class="signeeSelectedClass" />
                            <span :class="signeeSelectedClass" style="font-size: 14px">Initial</span>
                          </span>

                          <span v-if="box.type === 'dateSigned'">
                            <fa icon="calendar-alt" size="lg" class="me-2" :class="signeeSelectedClass" />
                            <span :class="signeeSelectedClass" style="font-size: 14px">Date Signed</span>
                          </span>
                          
                          <span v-if="box.type === 'name'" >
                            <fa icon="user-circle" size="lg" class="me-2" :class="signeeSelectedClass" /> 
                            <span :class="signeeSelectedClass" style="font-size: 14px">Name</span>
                          </span>

                          <span v-if="box.type === 'email'" >
                            <fa icon="at" size="lg" class="me-2" :class="signeeSelectedClass" />
                            <span :class="signeeSelectedClass" style="font-size: 14px">Email</span>
                          </span>

                          <span v-if="box.type === 'idPassport'" >
                            <fa icon="id-card" size="lg" class="me-2" :class="signeeSelectedClass" />
                            <span :class="signeeSelectedClass" style="font-size: 14px">ID/Passport</span>
                          </span>

                          <span v-if="box.type === 'textbox'" >
                            <fa icon="font" size="lg" class="me-2" :class="signeeSelectedClass" />
                            <span :class="signeeSelectedClass" style="font-size: 14px">Textbox</span>
                          </span>

                          <span v-if="box.type === 'textarea'" >
                            <fa icon="bars" size="lg" class="me-2" :class="signeeSelectedClass" />
                            <span :class="signeeSelectedClass" style="font-size: 14px">Textarea</span>
                          </span>

                          <span v-if="box.type === 'checkbox'" >
                            <fa icon="check-square" size="lg" class="me-2" :class="signeeSelectedClass" />
                            <span :class="signeeSelectedClass" style="font-size: 14px">Checkbox</span>
                          </span>
                        </td>
                        <td>
                          <div v-if="box.type === 'initial'" :class="signeeSelectedClass">
                            Appear in all pages
                          </div>

                          <div v-if="box.type !== 'initial'" class="form-check form-switch" :class="signeeSelectedClass">
                            <input class="form-check-input" type="checkbox" v-model="mandatory" :class="signeeSelectedClass" :disabled="docStatus !== 'DRAFT'">
                            <label class="ms-2" style="font-size: 14px">Mandatory</label>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                  
                  <div v-if="box.type === 'initial'" class="col-sm-12 col-md-6 col-lg-4 py-1">
                    <div class="input-group" >
                      <span class="input-group-text" :class="signeeSelectedClass">Exclude</span>
                      <input type="text" v-model="excludedPages" class="form-control form-control-sm" placeholder="Excluded pages i.e. 1, 5 (Optional)" :class="signeeSelectedClass" />
                    </div>
                    
                  </div>
                  <div v-if="box.type !== 'initial'" class="col-sm-12 col-md-6 col-lg-4 py-1">
                    <table width="100%" border="0">
                      <tr>
                        <td>
                          <select v-model="fontFamily" class="form-select form-select-sm" :class="signeeSelectedClass" :disabled="docStatus !== 'DRAFT'">
                            <option value="Courier">Courier</option>
                            <option value="CourierBold">CourierBold</option>
                            <option value="CourierOblique">CourierOblique</option>
                            <option value="CourierBoldOblique">CourierBoldOblique</option>
                            <option value="Helvetica">Helvetica</option>
                            <option value="HelveticaBold">HelveticaBold</option>
                            <option value="HelveticaOblique">HelveticaOblique</option>
                            <option value="HelveticaBoldOblique">HelveticaBoldOblique</option>
                            <option value="TimesRoman">TimesRoman</option>
                            <option value="TimesRomanBold">TimesRomanBold</option>
                            <option value="TimesRomanItalic">TimesRomanItalic</option>
                            <option value="TimesRomanBoldItalic">TimesRomanBoldItalic</option>
                          </select>
                        </td>
                        <td>
                          <select v-model="fontSize" class="form-select form-select-sm" :class="signeeSelectedClass" :disabled="docStatus !== 'DRAFT'">
                            <option :value="n" v-for="n in fontSizeRange" :key="n">{{n}}</option>
                          </select>
                        </td>
                        <td class="ps-1" :class="signeeSelectedClass" :style="'font-family: ' + fontFamily + '; font-size: '+ fontSize * uiRatio.toFixed(2) + 'px'">
                          <Popper class="popperDark" arrow hover content="Font preview">  
                            <span>Aa</span>
                          </Popper>
                        </td>
                      </tr>
                    </table>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-4 py-1">
                    <table width="100%" border="0" v-if="box.type === 'textbox' || box.type === 'textarea' || box.type === 'checkbox'">
                      <tr>
                        <td width="40%">
                          <input type="textbox" v-model="fontLabel" class="form-control form-control-sm" placeholder="Name" :class="signeeSelectedClass" :disabled="docStatus !== 'DRAFT'"/>
                        </td>
                        <td width="60%">
                          <input type="textbox" v-model="fontPlaceholder" class="form-control form-control-sm" placeholder="Description" :class="signeeSelectedClass" :disabled="docStatus !== 'DRAFT'"/>
                        </td>
                        <td>
                          <span class="px-1 isLink" :class="signeeSelectedClass" data-bs-toggle="modal" data-bs-target="#mdlShowJson" @click="viewJson">
                            <Popper class="popperDark" arrow hover content="Source code">
                              <fa icon="code" />
                            </Popper>
                          </span>
                        </td>
                      </tr>
                    </table>

                    <table border="0" v-if="!(box.type === 'textbox' || box.type === 'textarea' || box.type === 'checkbox')">
                      <tr>
                        <td class="pt-1">
                          <span class="px-1 isLink" :class="signeeSelectedClass" data-bs-toggle="modal" data-bs-target="#mdlShowJson" @click="viewJson">
                            <Popper class="popperDark" arrow hover content="Source code">
                              <fa icon="code" />
                            </Popper>
                          </span>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>

              <div class="border border-primary" v-if="showDebug">
                <!-- <div class="text-success" style="font-size: 10px">FrontEnd Ratio: {{viewWidth/pdfWidth}} (view-Width/pdf-Width) | <b>uiRatio:</b> {{uiRatio}}</div> -->
                <!-- <div class="text-success" style="font-size: 10px">PDF w: {{pdfWidth.toFixed(2)}} x {{pdfHeight.toFixed(2)}} | VIEW w: {{viewWidth}} x {{viewHeight}} |<b>uiRatio:</b> {{uiRatio}} | docId: {{docId}} | DragBox parent Height: {{ Math.ceil(pdfHeight*uiRatio)}}</div> -->
              
                <!-- <div class="text-success" style="font-size: 10px">pdfHeight: {{pdfHeight}}  viewHeight: {{viewHeight}} | {{viewHeight/pdfHeight}}</div> -->
                <!-- <div class="text-success" style="font-size: 10px">Window: {{winWidth}} x {{winHeight}}</div> -->
                <!-- <div class="text-success fw-bold" style="font-size: 10px">View: &nbsp; &nbsp; {{viewWidth}} x {{viewHeight}}</div> -->
                <!-- <div class="fw-bold">page = {{ page }} | {{ curPage }} / {{ getTotalPage }} | signeeId : {{signeeId}} | sort: {{ inSequence }} docId: {{docId}} | {{docStatus}}</div> -->
                <!-- <div class="text-warning">boxes [{{boxes.length}}] - All signees in doc (all pages)</div> -->
                <div class="text-warning" v-for="(b, bidx) in boxes" :key="b" style="font-size: 10px"><span class="text-danger">{{b.objects.length}} boxes</span> [{{b.seq}}] seq | {{b.name}} ({{b.signeeId}}) | isRole: {{b.isRole}} <span class="text-danger">{{b.mode}}</span><div class="ps-4" v-for="o in b.objects">{{ o }}</div></div>
                <!-- <div class="text-info" v-for="(b, bidx) in boxes" :key="b" style="font-size: 10px">- {{b}}</div> -->
                <!-- <div class="text-info" >{{ boxes }}</div> -->
                
                <!-- <div class="text-success" style="font-size: 10px"><b>docBoxes ({{docBoxes.length}}) - curSignee in doc (all pages) =</b> {{ docBoxes }}</div> -->
                <!-- <div class="text-success" style="font-size: 10px" v-for="b in docBoxes" :key="b"> ({{ b }}</div> -->
                
                <!-- <div class="text-primary" style="font-size: 10px"><b>pgBoxes ({{pgBoxes.length}}) - curSignee on curPage =</b> {{ pgBoxes }}</div> -->
                <!-- <div class="text-secondary fw-bold" style="font-size: 10px">docSignees [{{docSignees.length}}]</div>
                <div class="text-secondary" style="font-size: 10px" v-for="s in docSignees" :key="s">- {{s}}</div> -->

                <!-- <div class="text-info fw-bold" style="font-size: 10px">docs [{{docs.length}}]</div> -->
                <!-- <div class="text-info" style="font-size: 10px" v-for="s in docs" :key="s">- <b>{{s.name}}</b> - [{{s.signees.length}}] - {{s.signees}}</div> -->

                <!-- <div class="text-info" style="font-size: 10px">signees ({{signees.length}}) = {{ signees }}</div> -->
                
                <!-- <div class="text-info fw-bold" style="font-size: 10px">signees (Total: {{signees.length}})</div> -->
                <!-- <div class="text-info" v-for="(s, idx) in signees" :key="s" style="font-size: 10px">- {{idx}}: [{{s.seq}}] seq | <b>mode: {{s.mode}}</b> |{{s.name}} ( {{s.signeeId}} ), email: {{s.email}}, role:{{ s.role}}</div> -->
                <!-- <div class="text-info" v-for="s in signees" :key="s" style="font-size: 10px">- {{s}}</div> -->
                
                <!-- <div class="text-purple">box = {{ box }}</div> -->
                <!-- <div class="text-success">doc = {{ doc }}</div> -->
                <!-- <div class="text-purple" v-if="signee">selected signee =  {{signeeEmail}} | {{ signee }}</div> -->
                <!-- <div class="text-purple">isLoading = {{isLoading}} {{typeof isLoading}} <br>showUIBox: {{showUIBox}} {{typeof showUIBox}} <br>url: {{url}}</div> -->
                <!-- <div class="text-purple">Height: {{(Math.ceil(pdfHeight*uiRatio)+ 50)}} px | contentClass: {{contentClass}}</div> -->
              </div>

              <div style="position: relative; width: auto; height: 100%; margin-top: 1px; overflow: auto; top: 0px">

                <!-- <div class="parent text-start" id="pdfContainer" ref="pdfContainer" style="margin-top: 2px"> ORIG -->
                <!-- Required set height (px) or not detected when navigate to other page -->
                <div class="parent text-start" id="pdfContainer" ref="pdfContainer" :style="'margin-top: 2px; height: ' + (Math.ceil(pdfHeight*uiRatio)+ 50) + 'px'">
                    <component v-for="b in pgBoxes" :key="b.id" :is="newBox" :data="b" :boxClass="docStatus === 'DRAFT' ? signeeSelectedClass : 'boxDisabled ' + signeeSelectedClass" :signee="signeeId" :uiRatio="uiRatio"
                      @getBox="getBox" @getPosition="getPosition" @del="delBox" :isDragable="docStatus === 'DRAFT'" :viewWidth="viewWidth" />
                      
                    <vue-pdf-embed
                      id="pdfRef"
                      ref="pdfRef"
                      :source="url"
                      :page="page"
                      @password-requested="handlePasswordRequest"
                      @rendered="handleDocumentRender"
                      @click="closeAttr" 
                    />
                </div>
              </div>
              <!-- End of PDF -->
             
            </div>
          </div>
        </div>
      </div>  
    <!-- </div>  -->

    <!-- Modal --> 
      <div
        class="modal fade"
        id="mdlSignee"
        tabindex="-1"
        aria-labelledby="SortSignee"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Select Signee</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <!-- Tab -->
                <div class="modal-body">
                  <ul v-if="isWorkflow" class="nav nav-pills mb-4">
                    <li class="nav-item">
                        <a class="nav-link" :class="signeeOptToggle === 'signee' ? 'active' : ''" @click="signeeOptToggle = 'signee'" data-bs-toggle="pill" href="#signee">Signee</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="signeeOptToggle === 'workflow' ? 'active' : ''" @click="signeeOptToggle = 'workflow'" data-bs-toggle="pill" href="#workflow">Workflow</a>
                    </li>
                  </ul>

                  <div class="tab-content">
                    <div class="tab-pane container" :class="signeeOptToggle === 'signee' ? 'active' : 'fade'" id="signee">
                        <div style="max-height: 400px; overflow-y: auto">
                          <draggable
                            @change="sortSignee"
                            tag="ul"
                            :list="signees"
                            class="list-group"
                            handle=".handle"
                            item-key="signeeId"
                          >
                            <template #item="{ element, index }">
                              
                                <li class="list-group-item isLink" :class="signeeClasses[index] + 'Bg'" :style="signeeId === element.signeeId ? 'border-width: 3px' : ''" 
                                  v-if="signeeId !== element.signeeId" @click="selectSignee(element.signeeId, index, element.name, element.email, element)">
                                  <table width="100%" border="0">
                                      <tr>
                                        <td rowspan="2" v-if="inSequence" width="5%">
                                          <span v-if="hasSignee && element.mode === 'signee'">
                                            <fa icon="bars" class="handle me-3" size="lg"/>
                                          </span>
                                          <div v-else style="width: 32px"></div>
                                        </td>
                                        <td v-if="!inSequence" rowspan="2" width="5%" ></td>
                                        <td rowspan="2" width="40px">
                                          <span v-if="!element.isRole">
                                            <Avatar :isUser="element.isUser" :avatar="element.avatar" displayName="" :isVerified="true" class="fw-bold" width="35" height="35" />
                                          </span>
                                        </td>
                                        <td>
                                          <span v-if="inSequence && element.mode === 'signee'" class="badge bg-grey" style="margin-right: 3px">{{index+1}}</span> 
                                          <span class="fw-bold">{{ element.name }}</span>
                                        </td>
                                        <td width="80px" class="text-end"><span class="badge bg-grey">{{ element.role }}</span></td>
                                        <td rowspan="2" width="80px" class="text-end">
                                          <span class="isLink" v-if="element.mode === 'viewOnly'" @click="updSigneeAccess('notAccess', element)">
                                            <Popper class="popperDark" arrow hover content="View Only">
                                              <fa icon="eye" size="lg"/>
                                            </Popper>
                                          </span>
                                          <span class="isLink" v-if="element.mode === 'notAccess'" @click="updSigneeAccess('viewOnly', element)">
                                            <Popper class="popperDark" arrow hover content="Not Accessible">
                                              <fa icon="eye-slash" size="lg"/>
                                            </Popper>  
                                          </span>
                                        </td>
                                        <!-- <td rowspan="2" width="40px" class="text-end">selected</td> -->
                                      </tr>
                                      <tr>
                                        <td>{{ element.email }}</td>
                                      </tr>
                                    </table>
                                </li>

                                <!-- Selected signee -->
                                <li v-else class="list-group-item isLabel" :class="signeeClasses[index] + 'Bg'" :style="signeeId === element.signeeId ? 'border-width: 3px' : ''" >
                                  <table width="100%" border="0" >
                                      <tr>
                                        <td rowspan="2" v-if="inSequence" width="5%">
                                          <span v-if="hasSignee && element.mode === 'signee'">
                                            <fa icon="bars" class="handle me-3" size="lg"/>
                                          </span>
                                          <div v-else style="width: 32px"></div>
                                        </td>
                                        <td v-if="!inSequence" rowspan="2" width="5%" ></td>
                                        <td rowspan="2" width="40px">
                                          <span v-if="!element.isRole">
                                            <Avatar :isUser="element.isUser" :avatar="element.avatar" displayName="" :isVerified="true" class="fw-bold" width="35" height="35" />
                                          </span>
                                        </td>
                                        <td>
                                          <span v-if="inSequence && element.mode === 'signee'" class="badge bg-grey" style="margin-right: 3px">{{index+1}}</span> 
                                          <span class="fw-bold">{{ element.name }}</span>
                                        </td>
                                        <td width="80px" class="text-end"><span class="badge bg-grey">{{ element.role }}</span></td>
                                        <td rowspan="2" width="80px" class="text-end">
                                          <span class="isLink" v-if="element.mode === 'viewOnly'" @click="updSigneeAccess('notAccess', element)">
                                            <Popper class="popperDark" arrow hover content="View Only">
                                              <fa icon="eye" size="lg"/>
                                            </Popper>
                                          </span>
                                          <span class="isLink" v-if="element.mode === 'notAccess'" @click="updSigneeAccess('viewOnly', element)">
                                            <Popper class="popperDark" arrow hover content="Not Accessible">
                                              <fa icon="eye-slash" size="lg"/>
                                            </Popper>  
                                          </span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td> {{ element.email }}</td>
                                      </tr>
                                    </table>
                                </li>

                            </template>
                          </draggable>
                        </div>
                    </div>

                    <div class="tab-pane container" :class="signeeOptToggle === 'workflow' ? 'active' : 'fade'" id="workflow">
                        <select v-model="workflowId" class="form-select mb-3">
                            <option value="null">Select workflow</option>
                            <option :value="w.id" v-for="w in arrWorkflow" :key="w.id">{{w.title}}</option>
                        </select> 
                        <div v-if="isWorkflow && workflowId !== 'null'">
                            <div v-for="(w, wIdx) in arrWorkflowSignee" :key="w.signeeId">    
                                <!-- 1st Item --> 
                                <div v-if="wIdx === 0" @click="selectWorkflowSignee(wIdx, w.signeeId, w.name, w.isRole)" class="list-group-item isLabel py-2 isLink" :class="signeeClasses[wIdx] + 'Bg'" :style="workflowSigneeStep-1 === wIdx ? 'border-width: 3px' : ''" style="border-top-left-radius: 5px; border-top-right-radius: 5px;">
                                  <span class="me-2">STEP {{wIdx+1}}</span>
                                  <span v-if="w.isRole" class="badge bg-grey">{{w.name}}</span>
                                  <span v-else>{{w.name}}</span>
                                </div>
                                <!-- Last Item -->
                                <div v-else-if="wIdx === (arrWorkflowSignee.length-1)" @click="selectWorkflowSignee(wIdx, w.signeeId, w.name, w.isRole)" class="list-group-item isLabel py-2 isLink" :class="signeeClasses[wIdx] + 'Bg'" :style="workflowSigneeStep-1 === wIdx ? 'border-width: 3px' : ''" style="border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;">
                                  <span class="me-2">STEP {{wIdx+1}}</span>
                                  <span v-if="w.isRole" class="badge bg-grey">{{w.name}}</span>
                                  <span v-else>{{w.name}}</span>
                                </div>
                                <!-- Middle Item -->
                                <div v-else @click="selectWorkflowSignee(wIdx, w.signeeId, w.name, w.isRole)" class="list-group-item isLabel py-2 isLink" :class="signeeClasses[wIdx] + 'Bg'" :style="workflowSigneeStep-1 === wIdx ? 'border-width: 3px' : ''" >
                                  <span>  
                                    <span class="me-2">STEP {{wIdx+1}}</span>
                                    <span v-if="w.isRole" class="badge bg-grey">{{w.name}}</span>
                                    <span v-else>{{w.name}}</span>
                                  </span>
                                </div>

                            </div>
                        </div>
                    </div>
                  </div> 
                  <!-- Tab End -->
                  
                </div>
                <div class="modal-footer">
                  <table border="0" width="100%">
                    <tr>
                      <td>
                        <div v-if="proj.mode === 'SIMPLE' && hasSignee" class="form-check form-switch mb-2">
                          <input class="form-check-input" type="checkbox" v-model="inSequence" :disabled="docStatus !== 'DRAFT'">
                          <span class="text-grey">Sort signees, sign document according to the sequence order.</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-end">
                        <button type="button" class="btn btn-secondary me-2" data-bs-dismiss="modal">
                          <fa icon="xmark" class="me-2" />Cancel
                        </button>
                        <button v-if="signeeOptToggle === 'signee'" type="button" @click="updSigneeOrder" class="btn btn-secondary" :disabled="docStatus !== 'DRAFT'" data-bs-dismiss="modal">
                          <fa icon="floppy-disk" size="lg" class="me-2" />Save
                        </button>
                        <button v-if="signeeOptToggle === 'workflow'" type="button" class="btn btn-secondary" :disabled="docStatus !== 'DRAFT'" data-bs-dismiss="modal">
                          <fa icon="floppy-disk" size="lg" class="me-2" />Save
                        </button>
                      </td>
                    </tr>
                  </table>
                  
                </div>
            </div><!-- modal-content -->
        </div><!-- modal-dialog --> 
      </div>
      <!-- Modal End--> 

      <div
        class="modal fade"
        id="mdlDoc"
        tabindex="-1"
        aria-labelledby="ShowDoc"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered text-dark">
          <div class="modal-content">
            <div class="modal-header border-0">
              <h5 class="modal-title" id="exampleModalLabel"> Select Document <span class="badge bg-grey">{{docs.length}}</span></h5>
              <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <!-- <select v-model="docId" class="form-select" size="10">
                <option :value="d.documentId" v-for="(d, index) in docs" :key="d.documentId">
                  <DocStatus :status="d.status" class="" />{{ (d.name.length > 40 ? d.name.substring(0, 40) + '...' : d.name) }}
                </option>
              </select> -->
              <div class="row px-1" >
                <div class="col-12" style="height: 250px; overflow-y: auto">
                  <div class="list-group">
                    <!-- <a href="#" @click="docId = d.documentId" v-for="(d, index) in docs" :key="d.documentId" class="list-group-item list-group-item-action" :class="docId === d.documentId ? 'bg-light': ''"> -->
                    <a href="#" @click="docId = d.documentId" v-for="(d, index) in docs" :key="d.documentId" class="list-group-item list-group-item-action" :class="docId === d.documentId ? 'list-item-active': ''">
                      <DocStatus :status="d.status" class="" />{{ (d.name.replace('.pdf', '').length > 40 ? d.name.substring(0, 40) + '...' : d.name.replace('.pdf', '')) }}
                      <span v-if="docId === d.documentId" class="float-end"><fa icon="check" size="lg" /></span>
                      <Popper class="popperDark" arrow hover content="Total Signee">
                        <span class="badge bg-grey isTooltipsLabel ms-1">{{d.signees.length}}</span>
                      </Popper>
                    </a>
                  </div> 
                </div> 
              </div> 

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    <fa icon="xmark" class="me-2" />Cancel
                </button>
                <button type="button" @click="changeDoc" class="btn btn-secondary" data-bs-dismiss="modal">
                    <fa icon="arrow-pointer" size="lg" class="me-2" /> Apply
                </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div
        class="modal fade"
        id="mdlShowJson"
        tabindex="-1"
        aria-labelledby="ShowJson"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered text-dark">
          <div class="modal-content">
            <div class="modal-header border-0">
              <h5 class="modal-title" id="exampleModalLabel">Source Code <span class="badge bg-grey">{{docs.length}}</span></h5>
              <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

              <div class="row px-1" >
                  <div class="col-12">
                    <textarea class="form-control" rows="16" ref="jsonObj" id="jsonObj" style="border-width: 0px; color: grey"></textarea>

                  </div>
              </div> 

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    <fa icon="xmark" class="me-2" />Cancel
                </button>
                <button type="button" class="btn btn-secondary" @click="copy">
                    <fa icon="code" size="lg" class="me-2" /> Copy
                </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal End -->

</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import VuePdfEmbed from 'vue-pdf-embed'
import { ref, computed, watch, inject, onMounted, onUnmounted } from 'vue'
import DragBox from '@/components/DragBox.vue' 
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib'
import { Tooltip } from 'bootstrap/dist/js/bootstrap.js'
import Popper from 'vue3-popper'
import draggable from 'vuedraggable'
import { useStore } from '@/stores/store'
import DocStatus from '@/components/DocStatus.vue'
import funcs from '@/functions/function'
import Avatar from '@/components/Avatar.vue'
import { useRoute } from 'vue-router'

export default {
  name: 'PdfDetail',
  components: { Alert, TopNavigation, VuePdfEmbed, degrees, PDFDocument, rgb, StandardFonts, Tooltip, Popper, draggable, DocStatus, Avatar /* , Modal */ },
  setup () {

      const showDebug = ref(false)

      // const url = ref('https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf')
      // const url = ref(null)
      const url = ref('')
      // const url = ref('https://pdf-lib.js.org/assets/with_update_sections.pdf')
      // const url = ref('https://pdf-lib.js.org/assets/dod_character.pdf')
      // const url = ref('https://www.clickdimensions.com/links/TestPDFfile.pdf')
      
      const axios = inject('axios')
      const store = useStore()
      const { getProjectName, getProjectId, getTotalPage } = useStore()
      const alert = ref([])
      const func = funcs
      const route = useRoute()

      const isWorkflow = ref(true) // enable workflow module
      
      const proj = ref({})
      const showUIBox = ref(false)
      const showToolbox = ref(true)
      const hasClickSignatoryToolbox = ref(false)
      const toolboxBtnIcon = ref(null)
      // const contentClass = ref('col-10 p-0')
      // const contentClass = ref('col-7 col-md-9 col-lg-10 p-0')
      const contentClass = ref('col-12 p-0')
      const showAttr = ref(false)
      const docs = ref([]) // Docs excluded REMOVED
      const doc = ref(null) // localStorage doc.json value
      const docSignees = ref([]) // doc Signees and objects
      const docId = ref(null) // Doc drop down menu
      const docName = ref(null)
      const docStatus = ref(null)
      const signeeOptToggle = ref('signee') // toggle the signee / workflow tabs in select signee modal
      const signeeOpt = ref('signee') // when click item below signee / workflow tabs
      const signees = ref([]) // project signees
      const signeeClasses = ref(["color0", "color1", "color2", "color3", "color4", "color5", "color6", "color7", "color8", "color9", "color10"])
      const signee = ref(null) // selected signee info
      const signeeId = ref(null) // selected signee
      const signeeSelectedClass = ref(null)
      const signeeName = ref(null)
      const signeeEmail = ref(null)
      const orderNum = ref(1)
      const hasSignee = ref(false) // true - has set at least signee

      const workflowId = ref(null)
      const workflowTitle = ref(null)
      const workflowSignee = ref(null)
      const workflowSigneeId = ref(null)
      const workflowSigneeIsRole = ref(null)
      const workflowSigneeStep = ref(null)
      const arrWorkflow = ref([])
      const arrWorkflowSignee = ref([])

      const curPage = ref(1) // update tally to page
      const objId = ref(0) // object id must be unique in doc, unique index num to generate object id, format: signeeId + objId
      const boxes = ref([])     // all signees boxes in doc
      const docBoxes = ref([])  // cur signee boxes in doc (all pages)
      const pgBoxes = ref([])   // cur signee boxes in current page

      const roles = ref([])
      const inSequence = ref(false)

      const x = ref(0)
      const y = ref(0)

      const box = ref(null)
      const jsonObj = ref('')
      const mandatory = ref(true)
      const fontFamily = ref(null)
      const fontSize = ref(null)
      const fontSizeRange = ref([])
      const fontLabel = ref(null)
      const fontPlaceholder = ref(null)
      const isBold = ref(false)
      const isItalic = ref(false)
      const isUnderline = ref(false)
      const dateSignedFormat = ref('DD-MM-YYYY')
      const excludedPages = ref([])

      const pdfWidth = ref(0)
      const pdfHeight = ref(0)
      const uiRatio = ref(0) // ratio between UI width and PDF actual width. 
      const screenPdfHeight = ref(0)
      const pdfContainer = ref(null)

      // In UI, obj x & y from backend need times uiRatio
      // PDF -> UI: PDF xy * uiRatio
      // UI -> PDF: UI xy / uiRatio

      // const winWidth = ref(window.innerWidth)
      // const onLeftDrawer = ref(props.showToolbox)
      // console.info('onLeftDrawer def', onLeftDrawer.value)
      // console.info('projectId', store.getProjectId.value)

      for (var i = 8; i < 20; i++) {
        fontSizeRange.value.push(i)
      }

      // default color
      signeeSelectedClass.value = signeeClasses.value[0]

      const modify = async () => {
          const existingPdfBytes = await fetch(url.value).then(res => res.arrayBuffer())
          const pdfDoc = await PDFDocument.load(existingPdfBytes)
          const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

            // Get the first page of the document
            const pages = pdfDoc.getPages()
            const firstPage = pages[0]

            // Get the width and height of the first page
            const { width, height } = firstPage.getSize()

            // Draw a string of text diagonally across the first page
            // y = pdfHeight - font size - y_coordinate
            firstPage.drawText('XXXXXX Name', {
            x: 88.89,
            y: pdfHeight.value - 10 - 37.1,
            size: 8,
            font: helveticaFont,
            color: rgb(0.95, 0.1, 0.1),
            rotate: degrees(0),
            })
            // color: rgb(0.95, 0.1, 0.1),

            // Serialize the PDFDocument to bytes (a Uint8Array)
            const pdfBytes = await pdfDoc.save()
            // download(pdfBytes, "modified.pdf", "application/pdf")
            // const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true })
            // this.$refs.mypdf.src = pdfDataUri

            // download file
            /* const blob = new Blob([pdfBytes], { type: "application/pdf" })
            const link = document.createElement("a")
            link.href = URL.createObjectURL(blob)
            link.download = 'modified.pdf'
            link.click()
            URL.revokeObjectURL(link.href) */

            console.info('pageSize', width + ' | ' + height)

            // Show Changed result to website
            url.value = pdfBytes
      }

      watch([workflowId], () => {
        console.info('here', workflowId.value)
        if (workflowId.value === 1001) {
          workflowTitle.value = 'WorkFlow Testing Number One'
        }

        if (workflowId.value === 1002) {
          workflowTitle.value = 'Testing 2'
        }

        arrWorkflowSignee.value = [
          { "isRole": true, "signeeId": "Developer", "name": "Developer", "email": "", "node": "node1" }, 
          { "isRole": false, "signeeId": "DED17C76E24F11EC89D6FB54B2801234", "name": "Enerson", "email": "enerson.yap@genusis.com", "node": "node3" }, 
          { "isRole": true, "signeeId": "Support", "name": "Support", "email": "", "node": "node4" }, 
          { "isRole": true, "signeeId": "CS", "name": "CS", "email": "", "node": "node5" }, 
          { "isRole": true, "signeeId": "Developer", "name": "Developer", "email": "", "node": "node6" }, 
          { "isRole": false, "signeeId": "6E7A09F8E24E11EC89D6FB54B2806B93", "name": "Arshad", "email": "arshad@genusis.com", "node": "node7" }, 
          { "isRole": false, "signeeId": "DED17C76E24F11EC89D6FB54B2801234", "name": "Enerson", "email": "enerson.yap@genusis.com", "node": "node8" }, 
          { "isRole": true, "signeeId": "Support", "name": "Support", "email": "", "node": "node9" }, 
          { "isRole": true, "signeeId": "CS", "name": "CS", "email": "", "node": "node10" }
        ]
      })

      const getWorkflow = () => {

          arrWorkflow.value = [
            {
                id: 1001,
                title: 'WorkFlow Testing Number One'
            },
            {
                id: 1002, 
                title: 'Testing 2'
            },
          ]
      }

      const selectWorkflowSignee = (signeeIdx, signeeId, signeeName, isRole) => {
          signeeOpt.value = 'workflow'
          signeeOptToggle.value = 'workflow'

          let step = signeeIdx + 1

          workflowSignee.value = signeeName
          workflowSigneeId.value = signeeId
          workflowSigneeIsRole.value = isRole
          workflowSigneeStep.value = step

          signeeSelectedClass.value = signeeClasses.value[signeeIdx]

          localStorage.setItem('signeeOpt', 'workflow')
          // localStorage.setItem('signeeClass', signeeClasses.value[signeeIdx])
          localStorage.setItem('wfSigneeClass', signeeClasses.value[signeeIdx])
          localStorage.setItem('wfSigneeId', signeeId)
          localStorage.setItem('wfSigneeName', signeeName)
          localStorage.setItem('wfSigneeIsRole', isRole)
          localStorage.setItem('wfSigneeStep', step)

          // worflowId, workflowTitle
          // signeeId, signeeName, isRole

          console.info('STEP', signeeIdx)
          // console.info('s', s)

      }

      const closeAlert = (index) => {
          alert.value.splice(index, 1)
      }

      const add = async (type1, y1) => {

        console.info('------ ADD func', y1)
        // console.info('------ ADD func', signeeId.value)
        objId.value = docBoxes.value.length + 1
        while (chkObjIdExist(objId.value) === true) {
          objId.value++
        }

        // console.info('len ', docBoxes.value.length, signeeId.value)
        // Add 1st object
        if (boxes.value.length === 0) {

            if (roles.value.includes(signeeId.value)) {
              boxes.value.push({ signeeId: signeeId.value, name: signeeName.value, email: signeeEmail.value, isRole: true, seq: 0, status: 'PENDING', dateSigned: '0', objects:[{id: signeeId.value + '' + objId.value, type: type1, label: '', placeholder: '', isMandatory: mandatory.value, format: {}, position: {page: curPage.value, x: 255, y: y1}}] })
            } else {
              boxes.value.push({ signeeId: signeeId.value, name: signeeName.value, email: signeeEmail.value, isRole: false, seq: 0, status: 'PENDING', dateSigned: '0', objects:[{id: signeeId.value + '' + objId.value, type: type1, label: '', placeholder: '', isMandatory: mandatory.value, format: {}, position: {page: curPage.value, x: 255, y: y1}}] })
            }

        } else {

            let signeeExist = false
            boxes.value.forEach((b1, bIdx1) => {

              if (b1.signeeId === signeeId.value) {
                signeeExist = true
                boxes.value[bIdx1].objects.push({id: signeeId.value + '' + objId.value, type: type1, label: '', placeholder: '', isMandatory: mandatory.value, format: {}, position: {page: curPage.value, x: 255, y: y1}})
              }
            })

            if(!signeeExist) {
                boxes.value.push({ signeeId: signeeId.value, name: signeeName.value, email: signeeEmail.value, isRole: false, seq: 0, status: 'PENDING', dateSigned: '0', objects:[{id: signeeId.value + '' + objId.value, c: 'ccccccc', type: type1, label: '', placeholder: '', isMandatory: mandatory.value, format: {}, position: {page: curPage.value, x: 255, y: y1}}] })
            }
        }

        hasSignee.value = true
        setTimeout(() => loadBoxesObjects(), 1000)

        // update signee
        updDocsSignee()

      }

      const updDocsSignee = () => {

        let addDocsSignee = false
        if (docs.value.length > 0) {
            docs.value.forEach((item, idx) => {
                if (item.documentId === docId.value) {
                    if (item.signees && item.signees.length > 0) {
                      let isExist = false
                        item.signees.forEach((sItem, sIdx) => {
                            if (sItem.signeeId === signeeId.value) {
                                isExist = true
                            }
                        })

                        if (!isExist) {
                          addDocsSignee = true
                        }

                    } else {
                        addDocsSignee = true
                    }

                    if (addDocsSignee) {
                        if (signee.value && signee.value.length > 0) {
                            docs.value[idx].signees.push(JSON.parse(signee.value))
                        } else {
                            docs.value[idx].signees.push({ name: signeeName.value, email: signeeEmail.value, signeeId: signeeId.value})
                        }
                    }
                }
                
            })
            

        } //docs > 0
      }

      const newBox = computed(() => {
        // console.info('------ newBox func')
          return DragBox
      })

      const getPosition = (id1, x1, y1, signee1, label1, placeholder1, mandatory1, page1, type1, fontFamily1, fontSize1, width1, height1, excludedPages1) => {
          // console.info('******** getPosition func', id1, x1, y1)
          x.value = x1
          y.value = y1
          
          boxes.value.forEach((item, idx) => {
              if (item.signeeId === signee1) {
                  item.objects.forEach((obj, objIdx) => {
                      if (obj.id === id1) {
                          boxes.value[idx].objects[objIdx] = { id: id1, type: type1, label: label1, placeholder: placeholder1, isMandatory: mandatory1, format: { font: fontFamily1, size: fontSize1}, position: { page: page1, x: x1, y: y1}}
                          //20220610 if (type1 !== 'initial' && type1 !== 'fullSignature') {
                          if (type1 !== 'fullSignature') {
                              getBox(id1, x1, y1, signee1, label1, placeholder1, mandatory1, page1, type1, fontFamily1, fontSize1, excludedPages1)
                          }
                      }
                  })
              }
          })
          setTimeout(() => loadBoxesObjects(), 500)
      }

      const getBox = (id1, x1, y1, signee1, label1, placeholder1, mandatory1, page1, type1, fontFamily1, fontSize1, excludedPages1) => {
        // Trigger when change position or click dragbox - to show attribute/property section
        // console.info('getBox', id1, x1, y1, signee1, label1, placeholder1, mandatory1, page1, type1, fontFamily1, fontSize1)
        
        showAttr.value = true
        box.value = { id: id1, type: type1, label: label1, placeholder: placeholder1, isMandatory: mandatory1, format:{font: fontFamily1, size: fontSize1}, position:{page: page1, x:x1, y: y1}, excludedPages: excludedPages1}

        console.info('----getBox', JSON.stringify(box.value))

        mandatory.value = mandatory1
        fontLabel.value = label1
        fontPlaceholder.value = placeholder1
        fontFamily.value = fontFamily1
        fontSize.value = fontSize1
        excludedPages.value = excludedPages1
      }

      const delBox = (id1) => {
        docBoxes.value.forEach((item, idx) => {
          if (item.id === id1) {
            docBoxes.value.splice(idx, 1)
          }
        })

        if (docBoxes.value.length === 0) {
            console.info('-------------DEL ', docId.value, signeeId.value, signeeEmail.value)
            console.info('-------------DEL docs', JSON.stringify(docs.value))
            if (docs.value && docs.value.length > 0) {
                docs.value.forEach((item, idx) => {
                    // console.info('item', JSON.stringify(item))

                    if (item.documentId === docId.value) {
                        // docs.value.splice(idx, 1)
                        if (item.signees && item.signees.length > 0) {

                            item.signees.forEach((sItem, sIdx) => {
                                if(sItem.email === signeeEmail.value) {
                                    docs.value[idx].signees.splice(sIdx, 1)
                                }
                            })
                        }
                    }
                })
            }

        }

        setTimeout(() => {
          getDocBoxesToPgBoxes()
        }, 1000)
      }

      const updPageNum = (page1) => {
        curPage.value = page1
        getDocBoxesToPgBoxes()
      }

      watch([fontFamily, fontSize, fontLabel, fontPlaceholder, mandatory, excludedPages], async () => {
        // Update object properties
        // console.info('-- watch:', box.value.type, box.value.id)
        // console.info('-- watch:' + box.value.id, fontFamily.value, fontSize.value, fontLabel.value, fontPlaceholder.value)

        // Do not apply font-family and font size to object 
        /* if (fontFamily.value !== null && fontFamily.value !== undefined) {
            document.getElementById('box' + box.value.id).style.fontFamily = fontFamily.value.toLowerCase()
        } 

        if (fontSize.value !== null && fontSize.value !== undefined) {
            document.getElementById('box' + box.value.id).style.fontSize = fontSize.value + 'px'
        } */

        boxes.value.forEach((item, idx) => {
            if (item.signeeId === signeeId.value) {
                item.objects.forEach((obj, objIdx) => {
                    if (obj.id === box.value.id) {
                        boxes.value[idx].objects[objIdx].label = fontLabel.value
                        boxes.value[idx].objects[objIdx].placeholder = fontPlaceholder.value
                        boxes.value[idx].objects[objIdx].isMandatory = mandatory.value
                        boxes.value[idx].objects[objIdx].format.font = fontFamily.value
                        boxes.value[idx].objects[objIdx].format.size = fontSize.value
                        boxes.value[idx].objects[objIdx].excludedPages = excludedPages.value

                        box.value = boxes.value[idx].objects[objIdx]
                        jsonObj.value = JSON.stringify(box.value)
                        console.info('---- WATCH', typeof jsonObj.value, jsonObj.value)

                        console.info('##this box', JSON.stringify(boxes.value[idx].objects[objIdx]))

                    }
                })
            }
        })

        setTimeout(() => loadBoxesObjects(), 100)

        // showUIBox.value = true
        // showUIBox.value = false
        setTimeout(() => showUIBox.value = true, 500) // For reload component purpose
      })

      const toggleToolbox = async () => {
        
        localStorage.setItem('hasClickSignatoryToolbox', true)
        // await saveDoc()
        
        showToolbox.value = !showToolbox.value
        localStorage.setItem('leftDrawer', showToolbox.value)

        // location.reload() - Hide due to use floating toolbar which no need detect size
      }
      
      const closeAttr = () => {
        showAttr.value = false
        // console.info('closeAttr', '')
      }

      const getFieldPage = (field) => {
        let pages = []
        docBoxes.value.forEach((item, idx) => {
            if (item.type === field) {
               if (!pages.includes(item.position.page)) {
                 pages.push(item.position.page)
               }
            }
        })
        return pages
      }

      const sortSignee = () => {
        console.info('------ sortSignee func')
        const list1 = []
        const list2 = []
        signees.value.forEach((item, index) => {
          if (item.objects.length === 0) {
            list2.push(item)
          } else {
            list1.push(item)
          }
        })

        signees.value = []
        setTimeout(() => {
          list1.forEach((item, index) => {
            signees.value.push(item)
          })
        }, 100)
        
        setTimeout(() => {
          list2.forEach((item, index) => {
            signees.value.push(item)
          })
        }, 200)
      }

      const updSigneeAccess = (opt, s) => {
          console.info('updSigneeAccess s', opt, JSON.stringify(s))

          if (opt === 'viewOnly') {
            docSignees.value.push({seq: s.seq, name: s.name, role: s.role, email: s.email, isRole: s.isRole, status: s.status, objects: s.objects, signeeId: s.signeeId, dateSigned: s.dateSigned})
            setTimeout(() => setProjectSigneeObj(false), 1000)

          } else {

            // notAccess
            let tmp = []
             docSignees.value.forEach((item, idx) => {
               if (item.signeeId !== s.signeeId) {
                 tmp.push(item)
               }
            })
            docSignees.value = tmp

            let tmpB = []
            boxes.value.forEach((item, idx) => {
               if (item.signeeId !== s.signeeId) {
                 tmpB.push(item)
               }
            })

            boxes.value = tmpB

            setTimeout(() => {
              setProjectSigneeObj(false)
              loadBoxesObjects()
            }, 500)

            console.info('selected Signee', signeeId.value, signeeName.value, signeeEmail.value)
            console.info('selected Signee class', signeeSelectedClass.value)
            console.info('selected Signee info', JSON.stringify(signee.value))
          }
      }

      const selectSignee = (id, index, name, email, e) => {
        // console.info('---- selectSignee', id, JSON.stringify(e))
        console.info('---- selectSignee func', signeeClasses.value[index])

        signeeOpt.value = 'signee'
        signeeOptToggle.value = 'signee'

        signeeId.value = id
        signeeSelectedClass.value = signeeClasses.value[index]
        signeeName.value = name
        signeeEmail.value = email
        signee.value = e

        localStorage.setItem('signeeOpt', 'signee')

        localStorage.setItem('signeeClass', signeeClasses.value[index])
        localStorage.setItem('signeeId', id)
        localStorage.setItem('signeeName', name)
        localStorage.setItem('signeeEmail', email)
        localStorage.setItem('signee', e)

        // Update old signee object to boxes
        // Get new signee to docBoxes
        let hasMatched = false
        boxes.value.forEach((item, idx) => {
          if (item.signeeId === id) {
            hasMatched = true
            docBoxes.value = item.objects
            
          }
        })

        if (!hasMatched) {
          docBoxes.value = []
        }

        // sort pgBoxes
        setTimeout(() => getDocBoxesToPgBoxes(), 500)
      }

      const updSigneeOrder = async() => {
        console.info('------ updSigneeOrder func')

        // Update seq to boxes
        let seq = 0
        signees.value.forEach((item, idx) => {
          console.info('signee ', item.name + ' (' + item.signeeId + ')')
          seq = idx + 1
          boxes.value.forEach((bItem, bIdx) => {
            // console.info('boxesItem', JSON.stringify(boxesItem))
            // console.info('---- ', bItem.signeeId, bItem.objects.length)
            if (bItem.signeeId === item.signeeId && bItem.objects.length > 0) {
              console.info(' == ', bItem.name, bItem.seq + ' - set seq:' + seq)
              boxes.value[bIdx].seq = seq
              signees.value[idx].seq = seq
            }
          })
        })

        signeeOpt.value = 'signee'

        setTimeout(() => loadBoxesObjects(), 500)
        setTimeout(() => saveDoc(), 1000)
      }

      const setProjectSigneeObj = (setDefaultSignee) => {
        /* combine docsignees obj to project signees for select signee modal
           add mode: 
            - signee - Signee has objects in doc
            - viewOnly - Signee without object but exists in doc
            - notAccess - Signee not exists in doc but only in project
            set docBoxes
        */
        if (showDebug.value) {
            console.info('-- func: setProjectSigneeObj')
        }

        const objSignee = []
        const objViewOnly = []
        const objNotAccess = []

        signees.value.forEach((projSignee, projIdx, arr) => {
          signees.value[projIdx].objects = []
          signees.value[projIdx].mode = 'notAccess'
          if (docSignees.value.length > 0) {
            docSignees.value.forEach((item, idx) => {
              if (projSignee.signeeId === item.signeeId) {
                signees.value[projIdx].seq = item.seq
                signees.value[projIdx].objects = item.objects
                if (item.objects && item.objects.length > 0) {
                  signees.value[projIdx].mode = 'signee'
                  hasSignee.value = true
                } else {
                  signees.value[projIdx].mode = 'viewOnly'
                }
              } 
            })
          }
          // console.info('HERE', JSON.stringify(signees.value))
        })
        
        roles.value.forEach((r, rIdx) => {
          let roleExists = false
          signees.value.forEach((s, sIdx) => {
            if (s.signeeId === r ) {
              roleExists = true
            }
          })

          if (!roleExists) {
            signees.value.push({ signeeId: r, isRole: true, email: '', name: r, seq: 0, objects:[], mode: 'notAccess'})
          }
        })

        // sort by sequence
        signees.value.forEach((item, idx) => {
          if (item.mode === 'signee') {
            objSignee.push(item)
          }
          if (item.mode === 'viewOnly') {
            objViewOnly.push(item)
          }
          if (item.mode === 'notAccess') { 
            objNotAccess.push(item)
          }
        })

        objSignee.sort((a, b) => (a.seq > b.seq) ? 1 : -1)

        const newSignees = objSignee
        objViewOnly.forEach((item, idx) => newSignees.push(item))
        objNotAccess.forEach((item, idx) => newSignees.push(item))

        signees.value = newSignees

        // Set default signee
        if (setDefaultSignee) {

          if (signeeId.value === null) {

            let localSigneeId = localStorage.getItem('signeeId')
            if (localSigneeId === null || localSigneeId === '' || localSigneeId === undefined) {

                // Set default signee
                signeeId.value = signees.value[0].signeeId
                signeeName.value = signees.value[0].name
                signeeEmail.value = signees.value[0].email
                signee.value = signees.value[0]

                localStorage.setItem('signeeOpt', 'signee'),
                localStorage.setItem('signeeId', signees.value[0].signeeId),
                localStorage.setItem('signeeName', signees.value[0].name)
                localStorage.setItem('signeeEmail', signees.value[0].email)
                localStorage.setItem('signee', JSON.stringify(signees.value[0]))
                localStorage.setItem('signeeClass', signeeClasses.value[0])

            } else {

                localStorage.setItem('signeeOpt', 'signee'),
                signeeId.value = localSigneeId
                signeeName.value = localStorage.getItem('signeeName')
                signeeEmail.value = localStorage.getItem('signeeEmail')
                signee.value = localStorage.getItem('signee')

                signeeSelectedClass.value = localStorage.getItem('signeeClass')
            }
              
          }
        }
      }

      const getRole = () => {
        axios.get( '/signon/signeeRole/list').then((res) => {

          // console.info('getRole | RES', JSON.stringify(res.data.data))
          if (res.data.status === 1) {
            roles.value = res.data.data
          } 
        })
        .catch((error) => {
          console.log('getRole | FAILURE', JSON.stringify(error))
        })
      }

      const changeDoc = async (event) => {
        
        if (showDebug.value) {
            console.info('------ func: changeDoc')
        }

        docs.value.forEach((item) => {
          if (item.documentId === docId.value) {
            docName.value = item.name
            url.value = item.viewUrl
            curPage.value = 1

            localStorage.setItem('documentId', docId.value)
            localStorage.setItem('documentName', item.name)
            localStorage.setItem('documentUrl', item.viewUrl)
          }
        })

        await getDocument(docId.value)
        await setProjectSigneeObj(false)
        await loadBoxesObjects()
        await refreshPgBoxes()  // Reload dragbox component purpose or dragboxes not reload when same signee selected for different doc issue

        setTimeout(() => showUIBox.value = true, 1800)
      }

      const refreshPgBoxes = () => {

        if (showDebug.value) {
            console.info('------ func: refreshPgBoxes')
        }

        // Reload dragbox component purpose or dragboxes not reload when same signee selected for different doc issue
        const tmpPgBoxes = pgBoxes.value
        pgBoxes.value = []

        setTimeout(() => {
          pgBoxes.value = tmpPgBoxes
          return pgBoxes.value
        }, 500)
      }

      const saveDoc = async () => {
            boxes.value = convObj('pdf', boxes.value) 

            try {

                let p = {
                  isSort: inSequence.value === undefined ? false : inSequence.value,
                  signees: boxes.value
                }

                // if (showDebug.value) {
                    console.info('---- saveDoc', JSON.stringify(p))
                // }

                const res = await axios.put('/signon/' + getProjectId.value + '/' + docId.value, p)
                
                if (res.data.status === 1) {

                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Document is saved."
                    })

                    boxes.value = convObj('ui', boxes.value)
                }

            } catch (error) {

                console.log('saveDoc | FAILURE', JSON.stringify(error))
                alert.value.push({
                      class: "danger",
                      title: "ERROR",
                      message: func.convError(error)
                  })
            }
        }

      const getProject = (id) => {
        if (showDebug.value) {
            console.info('---- func: getProject', id)
        }

        return axios.get( '/signon/' + id).then((res) => {
          if (showDebug.value) {
              // console.info('getProj | RES', JSON.stringify(res.data.data))
              console.info('getProj status '+ typeof res.data.status, res.data.status)
          }
          
          if (res.data.status === 1) {
            proj.value = res.data.data

            // console.info('getProj - proj', JSON.stringify(proj.value))
            if (res.data.data.documents.length > 0) {
              // Filtered REMOVED doc
              res.data.data.documents.forEach((item, idx) => {
                  if (item.status !== 'REMOVED') {
                     docs.value.push(item)
                  }
              })
            }

            /* if (docId.value === null && docs.value[0].documentId !== null) {
              console.info('================== is NULL')
              // Set default document
              docId.value = docs.value[0].documentId
              docName.value = docs.value[0].name
              url.value = docs.value[0].viewUrl

              localStorage.setItem('documentId', docs.value[0].documentId)
              console.info('------ ------ getProject', 'docId', docId.value, 'set localstorage', docs.value[0].documentId)
            } */

            if (docId.value === null) {
              console.info('aaa')

              let localDocId = localStorage.getItem('documentId')
              if (localDocId === null || localDocId === '' || localDocId === undefined) {
                // Set default document
                docId.value = docs.value[0].documentId
                docName.value = docs.value[0].name
                url.value = docs.value[0].viewUrl

                console.info('aaa1', url.value)

                localStorage.setItem('documentId', docs.value[0].documentId)
                localStorage.setItem('documentName', docs.value[0].name)
                localStorage.setItem('documentUrl', docs.value[0].viewUrl)

              } else {

                docId.value = localDocId
                docName.value = localStorage.getItem('documentName')
                url.value = localStorage.getItem('documentUrl')

                console.info('aaa2', url.value)
              }
            } else {
              console.info('docId.value X NULL', typeof docId.value, docId.value)
            }
          } 
        })
        .catch((error) => {
          // console.log('getProj | FAILURE', JSON.stringify(error))
          console.log('getProj | FAILURE', error)
        })
      }

      const getDocument = (id) => {
        if (showDebug.value) {
            console.info('---- func: getDocument', id, docId.value)
        }

        return axios.get( '/signon/' + getProjectId.value + '/' + id)
        .then((res) => {

          pdfWidth.value = res.data.data.file.pageWidth
          pdfHeight.value = res.data.data.file.pageHeight

          if (uiRatio.value === 0) {
              // Cater for cannot capture uiRatio in handleDocument function
              uiRatio.value = pdfContainer.value.clientWidth/pdfWidth.value
              // console.info('---- SET uiRatio (Composition API)', uiRatio.value, pdfContainer.value.clientWidth,' / ', pdfWidth.value)
              console.info('---- SET uiRatio (Composition API)', uiRatio.value, pdfContainer.value.clientWidth,' / ', pdfWidth.value)
          }

          docStatus.value = res.data.data.status
          doc.value = { 
            isSort: res.data.data.isSort, 
            signees: convObj('ui', res.data.data.signees) 
          }
          docSignees.value = res.data.data.signees
          inSequence.value = res.data.data.isSort

          if (res.data.status === 1) {
            doc.value = computed({
                get: () => store.getDocument.value,
                set: store.updDocument(doc.value),
            })
            doc.value = store.getDocument.value
            boxes.value = res.data.data.signees
          } 

        })
        .catch((error) => {

          console.log('getDocument | FAILURE', JSON.stringify(error))
          alert.value.push({
              class: "danger",
              title: "ERROR",
              message: func.convError(error)
          })
        })
      }

      const loadBoxesObjects = async () => {
        // Apply boxes object to docBoxes & pgBoxes
        // Apply boxes object to signees & docSignees object 
        // console.info('------ loadBoxesObjects func')
        // console.info('------ loadBoxesObjects func', JSON.stringify(boxes.value))

        docBoxes.value = []
        pgBoxes.value = []

        boxes.value.forEach((item) => {
            if (item.signeeId === signeeId.value) {
                if (item.objects.length > 0) {
                    item.objects.forEach((obj, objIdx) => {
                       docBoxes.value.push(obj)
                       if (obj.position.page === curPage.value) {
                          pgBoxes.value.push(obj)
                       }
                    })
                }
            }

            // Update signees object from boxes
            signees.value.forEach((itemSignee, idxSignee) => {
                if (itemSignee.signeeId === item.signeeId) {
                    signees.value[idxSignee].objects = item.objects

                    if (signees.value[idxSignee].objects.length > 0) {
                      signees.value[idxSignee].mode = 'signee'
                    }
                }
                
            })

            // Update docSignees object from boxes
            docSignees.value.forEach((itemDSignee, idxDSignee) => {
                if (itemDSignee.signeeId === item.signeeId) {
                    docSignees.value[idxDSignee].objects = item.objects
                }
            })
        })
      }

      const loadSignees = () => {
        /* Update mode for signees based on docSignee object:
            - signee - Signee has objects in doc
            - viewOnly - Signee without object but exists in doc
            - notAccess - Signee not exists in doc but only in project
          Sort signees  
        */

        if (showDebug.value) {
            console.info('---- func: loadSignees', JSON.stringify(signees.value))
        }

        const objSignee = []
        const objViewOnly = []
        const objNotAccess = []

        signees.value.forEach((item, idx, arr) => {
          signees.value[idx].mode = 'notAccess'
          if (docSignees.value.length > 0) {
            docSignees.value.forEach((dItem, dIdx) => {
              if (item.signeeId === dItem.signeeId) {
                if (dItem.objects && dItem.objects.length > 0) {
                  signees.value[idx].mode = 'signee'
                } else {
                  signees.value[idx].mode = 'viewOnly'
                }
              } 
            })
          }
        })

        // sort by sequence
        signees.value.forEach((item, idx) => {
          if (item.mode === 'signee') objSignee.push(item)
          if (item.mode === 'viewOnly') objViewOnly.push(item)
          if (item.mode === 'notAccess') objNotAccess.push(item)
        })

        objSignee.sort((a, b) => (a.seq > b.seq) ? 1 : -1)

        const newSignees = objSignee
        objViewOnly.forEach((item, idx) => newSignees.push(item))
        objNotAccess.forEach((item, idx) => newSignees.push(item))

        signees.value = newSignees
      }

      const getDocBoxesToPgBoxes = () => {
        // Get pgBoxes from docBoxes
        // Upd signees object changes to signees (proj) & docSignees
        if (showDebug.value) {
          // console.info('-- func: getDocBoxesToPgBoxes page', JSON.stringify(docBoxes.value))
          console.info('-- func: getDocBoxesToPgBoxes')
        }

        pgBoxes.value = []
        docBoxes.value.forEach((item, idx) => {
          if (item.position.page === curPage.value)
          {
            pgBoxes.value.push(item)
          }
        })

        hasSignee.value = false
        signees.value.forEach((itemSignee, idxSignee) => {
          if (itemSignee.signeeId === signeeId.value) {

            signees.value[idxSignee].objects = docBoxes.value
            signeeSelectedClass.value = signeeClasses.value[idxSignee] // Set selected class color after sorting of signee, viewOnly, notAccess
            signees.value[idxSignee].mode = itemSignee.mode
          }

          if (signees.value[idxSignee].objects.length > 0) {
            hasSignee.value =  true
          }
          // console.info('s here', JSON.stringify(signees.value[idxSignee].objects))
        })

        docSignees.value.forEach((itemDC, idxDC) => {
          if (itemDC.signeeId === signeeId.value) {
            docSignees.value[idxDC].objects = docBoxes.value
          }
        })
      }

      const viewJson = () => {
        document.getElementById('jsonObj').value = '' + JSON.stringify(box.value, undefined, 2) + ' '
      }

      const copy = () => {
        // jsonObj.value.focus()
        // document.execCommand('copy')

        // document.getElementById('jsonObj').value = '' + JSON.stringify(box.value, undefined, 2) + ' '
        // document.getElementById('jsonObj').value = '' + JSON.stringify(box.value) + ' '

        document.getElementById('jsonObj').select()
        document.execCommand('copy')
      }

      onMounted(() => {

        // First time login
        if (route.query.fl === true || route.query.fl === 'true') {
            alert.value.push({
                class: "success",
                title: "SUCCESS",
                message: "Welcome to SignOn."
            })
        }

        // Not first time login
        if (route.query.fl === false || route.query.fl === 'false') {
            if (route.query.l) { 
                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Welcome back. Last login " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.l), new Date()) + ", last active " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.a), new Date()) + "."
                })
            }
        }
 
        if (localStorage.getItem('hasClickSignatoryToolbox') === 'true') {
          showToolbox.value = localStorage.getItem('leftDrawer') === 'true'

        } else {
          showToolbox.value = true
          localStorage.setItem('leftDrawer', true)
        }

        if (showToolbox.value) {
          toolboxBtnIcon.value = "chevron-left"
        } else {
          toolboxBtnIcon.value = "chevron-right"
        }


      })

      /* watch([showToolbox], async () => {
          if (showToolbox.value === true || showToolbox.value === 'true') {
              contentClass.value = 'col-7 col-md-9 col-lg-10 p-0'
          } else {
              contentClass.value = 'col-12 p-0'
          }
      }) */

      const iniLoad = async () => {
        if (showDebug.value) {
            console.info('---- func: iniLoad', store.getProjectId.value)
        }

        if (localStorage.leftDrawer === undefined || localStorage.leftDrawer === 'false') {
          localStorage.leftDrawer = false
          showToolbox.value = false
          toolboxBtnIcon.value = "chevron-right"
          
        } else { 
          showToolbox.value = (localStorage.leftDrawer === 'true')
          toolboxBtnIcon.value = "chevron-left"
        }

        getRole()
        getWorkflow()

        if (store.getProjectId.value) {
          if (showDebug.value) {
            console.info('-------- func: iniLoad ', 'getProject', store.getProjectId.value)
          }

          await getProject(store.getProjectId.value)
          // if (docId.value !== null) {
            await getDocument(docId.value)
          // }
          await getProjectSignees(store.getProjectId.value)

          setTimeout(() => setProjectSigneeObj(true), 800)
          setTimeout(() => loadBoxesObjects(), 1500)

          setTimeout(() => showUIBox.value = true, 2000)
        }
      }

      iniLoad()

      function getSigneeClass(signee1) {
        for (var i = 0; i < signees.value.length; i++) {
          if (signee1 === signees.value[i].signeeId) {
            return signeeClasses.value[i]
          }
        }
      }

      function getProjectSignees(id) {
          if (showDebug.value) {
            // console.info('-- func: getProjectSignees')
          }

          axios.get('/signon/' + id + '/signee/list'
            ).then((res) => {
                // console.info('getProjectSignees', JSON.stringify(res))
                if (res.data.status === 1) {
                    signees.value = res.data.data
                }
            })
            .catch((error) => {

                // console.info('getProjectSignees ERR', JSON.stringify(error))
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: func.convError(error)
                })
            })
      }

      function chkObjIdExist(id) {
        docBoxes.value.forEach((item, idx) => {
          if (item.id === signeeId.value + id) {
            return true
          }
        })
        return false
      }

      function convObj(convTo, val) {
        // convert to UI, xy  * uiRatio
        // convert to PDF, xy / uiRatio
        // console.info('---- func: convObj to ', convTo, 'uiRatio: ', uiRatio.value)

        let tmpSignees = val

        if (tmpSignees.length === 0) {
            return tmpSignees

        } else {

            if (convTo === 'ui') {
              // console.info('ui---- HERE')
              tmpSignees.forEach((sItem, sIdx) => {
                  
                  if (sItem.objects.length > 0) {
                      sItem.objects.forEach((item, idx) => {
                          tmpSignees[sIdx].objects[idx].position.x = item.position.x * uiRatio.value
                          tmpSignees[sIdx].objects[idx].position.y = item.position.y * uiRatio.value
                      })
                  }
              })
              return tmpSignees

            } else if (convTo === 'pdf') {
              // console.info('pdf---- HERE')
              tmpSignees.forEach((sItem, sIdx) => {

                  if (sItem.objects.length > 0) {
                      sItem.objects.forEach((item, idx) => {
                          tmpSignees[sIdx].objects[idx].position.x = item.position.x / uiRatio.value
                          tmpSignees[sIdx].objects[idx].position.y = item.position.y / uiRatio.value
                      })
                  }
              })

              return tmpSignees
            }
        } 

      }

      return { 
        showDebug, store, alert, closeAlert, newBox, boxes, docBoxes, pgBoxes, add, getPosition, delBox, isWorkflow, 
        iniLoad, showUIBox, url, getBox, func, 
        box, fontFamily, fontSize, fontSizeRange, isBold, isItalic, isUnderline, fontLabel, fontPlaceholder, excludedPages, mandatory, dateSignedFormat,
        proj, docs, doc, docSignees, docId, docName, docStatus, 
        signees, signee, signeeId, signeeClasses, selectSignee, signeeSelectedClass, getSigneeClass, signeeName, signeeEmail, setProjectSigneeObj, objId, 
        toggleToolbox, showToolbox, contentClass, toolboxBtnIcon,
        closeAttr, showAttr, inSequence, updSigneeOrder, getProject, getDocument, changeDoc, getProjectName, getProjectId,
        sortSignee, orderNum, saveDoc,
        curPage, getTotalPage, updPageNum, getDocBoxesToPgBoxes, roles, getRole, getFieldPage,
        pdfWidth, pdfHeight, screenPdfHeight, modify, uiRatio, updSigneeAccess,
        pdfContainer, hasSignee, copy, jsonObj, viewJson, 
        signeeOpt, signeeOptToggle, arrWorkflow, arrWorkflowSignee, selectWorkflowSignee,
        workflowId, workflowTitle, workflowSignee, workflowSigneeId, workflowSigneeIsRole, workflowSigneeStep, 
      }
  },
  data () {
    return {
      isLoading: false,
      page: 1,
      // page: localStorage.getItem('page'),
      pageCount: 1,
      goToPage: 1,
      showAllPages: false,
      scale: 1,
      transX: 0,
      transY: 0,
      winWidth: 0,
      winHeight: 0,
      viewWidth: 0, 
      viewHeight: 0, 
      /* getWindowWidth: 0, 
      getWindowHeight: 0, 
      getScreenWidth: 0,
      getScreenHeight: 0 */
      winTop: 0
    }
  },
  watch: {
      showAllPages() {
        this.page = this.showAllPages ? null : 1
        localStorage.setItem('page', this.page)
        
      },
      showWidth() {
        console.info('showWidth', this.$refs.pdfContainer.clientWidth)
        return this.$refs.pdfContainer.clientWidth
      },
      showHeight() {
        console.info('showHeight', this.$refs.pdfContainer.clientHeight)
        return this.$refs.pdfContainer.clientHeight
      },
      curPage(value) {
        // Change to first page if detected change document 
        if (value === 1 && value !== this.page) {
          this.navigatePage('first')
        }
      }
  },
  mounted () {
    // Initiate tooltip
    Array.from(document.querySelectorAll('button[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode))
    Array.from(document.querySelectorAll('span[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode))

    // console.info('mounted-- leftDrawer', '=' + this.leftDrawer)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()

    window.addEventListener("scroll", this.handleScroll, true)

  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll)

  },
  methods: {
    handleScroll() {
        let element = this.$refs.pdfContainer
        if (element !== null) {
            this.winTop = parseFloat(element.getBoundingClientRect().top).toFixed(2) * -1 + 90
        }
    },
    handleResize() {

      this.winWidth = window.innerWidth
      this.winHeight = window.innerHeight

      if (this.$refs.pdfContainer !== null) {
          this.viewWidth = this.$refs.pdfContainer.clientWidth // mounted only can use
          this.viewHeight = this.$refs.pdfContainer.clientHeight // mounted only can use
          // this.screenPdfHeight = this.pdfHeight*this.uiRatio
          // console.info('client w x h', this.$refs.pdfContainer.clientWidth, this.$refs.pdfContainer.clientHeight)
      }
    },
    handleDocumentRender() {
      console.info('---- handleDocumentRender', 'pdfWidth: ', this.pdfWidth)

      // Can't detect pdfWidth in handleResize() as not process getDocument() yet
      // this.uiRatio = parseFloat(this.viewWidth/this.pdfWidth).toFixed(2) * 1 // rounded to 2 decimals
      this.uiRatio = this.viewWidth/this.pdfWidth
      console.info('---- func: handleDocumentRender', 'SET uiRatio: ', this.uiRatio, this.viewWidth,' / ', this.pdfWidth)

      this.isLoading = false
      this.pageCount = this.$refs.pdfRef.pageCount

      localStorage.setItem('page', this.page)
      localStorage.setItem('totalPage', this.pageCount)
    },
    handlePasswordRequest(callback, retry) {
      callback(prompt(retry
        ? 'Enter password again'
        : 'Enter password'
      ))
    },
    navigatePage (opt) {
      if (opt === 'prev')
      {
          this.page--
          this.goToPage = this.page
      } else if (opt === 'next') {
          this.page++
          this.goToPage = this.page
      } else if (opt === 'first') {
          this.page = 1
          this.goToPage = this.page
      } else if (opt === 'last') {
          this.page = this.pageCount
          this.goToPage = this.page     
      } else if (opt !== '' && opt > 0) {
          this.page = opt * 1
          this.goToPage = opt * 1       
      } else {
          this.goToPage = this.goToPage * 1
          if (this.goToPage > 0 && this.goToPage <= this.pageCount) {
              this.page = this.goToPage
          } else { 
              this.page = 1
              this.goToPage = this.page
          }
      }

      localStorage.setItem('page', this.page)
    },
    zoomIn () {
      this.scale = this.scale + 0.1
      /* this.transX = this.transX + 44 - (this.scale * 4)
      this.transY = this.transY + 53 - (this.scale * 3) */ 

      document.getElementById('pdfContainer').style.transform = 'scale(' + this.scale + ', ' + this.scale + ') translate(' + this.transX + 'px, ' + this.transY + 'px)'
      document.getElementById('pdfContainer').style.transformOrigin = 'right top'
      // console.info('scale = ' + this.scale, this.transX + ' ' + this.transY)
      // console.info('win', window.innerWidth + ' ' + window.innerHeight)
    },
    zoomOut () {
      this.scale = this.scale - 0.1
      /* this.transX = this.transX - 40
      this.transY = this.transY - 50 */

      document.getElementById('pdfContainer').style.transform = 'scale(' + this.scale + ', ' + this.scale + ') translate(' + this.transX + 'px, ' + this.transY + 'px)'
      document.getElementById('pdfContainer').style.transformOrigin = 'right top'
    },
    zoomDefault () {
        this.scale = 1
        this.transX = 0
        this.transY = 0

        document.getElementById('pdfContainer').style.transform = 'scale(' + this.scale + ', ' + this.scale + ') translate(' + this.transX + 'px, ' + this.transY + 'px)'
        document.getElementById('pdfContainer').style.transformOrigin = 'right top'
    }
  }
}
</script>

// NOTE: fontFamily,fontSize pass from parent to DragBox component thr props, other from component to parent thr getPosition
// npm i vue3-draggable-resizable
// https://www.npmjs.com/package/vue3-draggable-resizable
// npm i vue-pdf-embed
// https://www.npmjs.com/package/vue-pdf-embed
// npm i pdf-lib
// https://www.npmjs.com/package/pdf-lib
// npm i downloadjs

// test https://www.npmjs.com/package/vue3-date-time-picker
// https://vue3datepicker.com/installation/#global
// npm i vue3-date-time-picker

// References
// https://www.designcise.com/web/tutorial/how-to-apply-css-opacity-to-background-color-only
// scan signature https://www.youtube.com/watch?v=0ArXBk6vS5U

<style>
body {
  color: #6E777E;
  font-size: 14px;
  overflow: hidden;
}

.parent {
  width: 100%;
  /* position: absolute; */
  top: 0px;
  left: 0px; 
  user-select: none;
  position: relative;
}
.box {
  border-style: dashed;
  border-width: 3px;
  cursor: pointer;
  border-radius: 5px;
}
.box:hover {
  border-style: dashed;
  border-width: 3px;
  cursor: move;
}
.box:active {
  cursor: move;
  border-style: dashed;
  border-width: 3px;
}

.boxSelected, .boxSelected:hover {
  border-width: 3px;
  border-style: solid;
}

.boxCal {
  padding-left: 8px;
  padding-right: 8px;
  width: 200px;
}

.boxDisabled {
  border-style: double;
  border-width: 3px;
  /* cursor: pointer; */
  border-radius: 5px;
}

.boxDisabled:hover {
  border-style: double;
  border-width: 3px;
}

.boxDisabled:active {
  border-style: double;
  border-width: 3px;
}

@media (max-width: 575.98px) {
    .boxDisabled   {
        font-size: 7px;
    }
    .boxDisabled > div > svg, .boxSignInitial > div > svg, .boxSignInitialDef > div > svg {
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 7px;
    }
    .boxDisabled > div > span, .boxSignInitial > div > span, .boxSignInitialDef > div > span {
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 7px;
    }
}

.vdr-container .active {
  border-style: solid;
}

.color0 {
  color: #1141AF;
  border-color: #1141AF;
}
.color0Bg {
  color: #1141AF;
  border-color: #1141AF;
  background-color: rgba(17, 65, 175, 0.2)
}
.color1 {
  color: #CD0275;
  border-color: #CD0275;
}
.color1Bg {
  color: #CD0275;
  border-color: #CD0275;
  background-color: rgba(205, 2, 117, 0.2)
}
.color2 {
  color: #00999D;
  border-color: #00999D;
}
.color2Bg {
  color: #00999D;
  border-color: #00999D;
  background-color: rgba(0, 153, 157, 0.2)
}
.color3 {
  color: #FF7302;
  border-color:  #FF7302;
}
.color3Bg {
  color: #FF7302;
  border-color:  #FF7302;
  background-color: rgba(255, 115, 2, 0.2)
}
.color4 {
  color: #AC6BC6;
  border-color:  #AC6BC6;
}
.color4Bg {
  color: #AC6BC6;
  border-color:  #AC6BC6;
  background-color: rgba(172, 107, 198, 0.2)
}
.color5 {
  color: #967218;
  border-color:  #967218;
}
.color5Bg {
  color: #967218;
  border-color:  #967218;
  background-color: rgba(243, 200, 91, 0.2)
}
.color6 {
  color: #7F7F7F;
  border-color:  #7F7F7F;
}
.color6Bg {
  color: #7F7F7F;
  border-color:  #7F7F7F;
  background-color: rgba(191, 191, 191, 0.2)
}
.color7 {
  color: #DF0808;
  border-color:  #DF0808;
}
.color7Bg {
  color: #DF0808;
  border-color:  #DF0808;
  background-color: rgba(241, 137, 137, 0.2)
}
.color8 {
  color: #1E90FF;
  border-color:  #1E90FF;
}
.color8Bg {
  color: #1E90FF;
  border-color:  #1E90FF;
  background-color: rgba(173, 216, 230, 0.2)
}
.color9 {
  color: #008000;
  border-color:  #008000;
}
.color9Bg {
  color: #008000;
  border-color:  #008000;
  background-color: rgba(142, 249, 142, 0.2)
}
.color10 {
  color: #9B497E;
  border-color:  #9B497E;
}
.color10Bg {
  color: #9B497E;
  border-color:  #9B497E;
  background-color: rgba(237, 162, 211, 0.2)
}


.handle {
  cursor: move;
}
.list-item-active {
  background-color: var(--navbar-light);
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--text-grey-link);
  text-decoration: none;
  background-color: var(--navbar-light);
}

.input-group-text {
  background-color: var(--bg-body-color);
}

.list-group-item {
    background-color: var(--toolbar-bg);
}

.toolbarFloat {
  position: fixed; 
  z-index: 2; 
  top: 41px;
  width: 255px; 
  opacity: 0.8; 

  background-color: var(--toolbar-bg);
  /* border:1px solid red; */
}

.toolboxBtnClose {
  position: fixed; 
  margin-top: 6px;
  width: 20px; 
  height: 45px; 
  padding: 11px 2px; 
  background-color: grey;
  border-top-right-radius: 8px; 
  border-bottom-right-radius: 8px; 
  z-index: 9; 
  opacity: 0.8; 
  cursor: pointer
}

.toolboxBtnOpen {
  position: fixed;
  margin-top: 6px;
  left: 255px; 
  width: 20px; 
  height: 45px; 
  padding: 11px 2px; 
  background-color: grey;
  border-top-right-radius: 8px; 
  border-bottom-right-radius: 8px; 
  z-index: 9; 
  opacity: 0.8; 
  cursor: pointer
}

.card-header {
    padding: 0px;
    border-bottom: 0px;
    background-color: var(--toolbar-bg);
}

.card-header .btn {
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 600;
}

.card-header .btn:focus {
    box-shadow: 0 0 0 0;
}

.card-body {
    padding: 5px 12px;
    background-color: var(--toolbar-bg);
}

</style>