<template>
  <Vue3DraggableResizable
    :id="'box' + id"
    :classNameDraggable="'box ' + boxClass + 'Bg'"
    :class="boxClass + 'Bg'"
    :initW="w"
    :initH="h"
    v-model:x="x"
    v-model:y="y"
    v-model:w="w"
    v-model:h="h"
    :parent="true"
    :draggable="isDragable"
    :resizable="false"
    classNameActive="boxSelected"
    >
    <!-- classNameActive="boxSelected" -->
    <!-- :style="'font-family:' + fontFamily + '; font-size:' + fontSize + 'px'"  -->
    
    <div v-if="objType === 'name'" @click="openAttr()" class="p-1" >
      <fa icon="user-circle" size="lg" class="me-1" /> 
      <span>Name</span>
    </div>

    <div v-if="objType === 'email'" @click="openAttr()" class="p-1" >
      <fa icon="user-circle" size="lg" class="me-1" /> 
      <span>Email</span>
    </div>

    <div v-if="objType === 'idPassport'" @click="openAttr()" class="p-1" >
      <fa icon="user-circle" size="lg" class="me-1" /> 
      <span>ID/Passport</span>
    </div>

    <div v-if="objType === 'textbox'" @click="openAttr()" class="p-1" >
      <fa icon="font" size="lg" class="me-1" /> 
      <span>Textbox</span>
    </div>

    <div v-if="objType === 'textarea'" @click="openAttr()" class="p-1" >
      <fa icon="bars" size="lg" class="me-1" /> 
      <span>Textarea</span>
    </div>

    <div v-if="objType === 'checkbox'" @click="openAttr()" class="p-1" >
      <fa icon="check-square" size="lg" class="me-1" /> 
      <span>Checkbox</span>
    </div>

    <div v-if="objType === 'dateSigned'" @click="openAttr()" class="p-1">
      <fa icon="calendar-alt" size="lg" class="me-1" />
      <span>Date Signed</span>
      <!-- <Datepicker :modelValue="date" @update:modelValue="setDate" :enableTimePicker="false" position="left" :format="title === 'Date (YYYY-MM-DD)' ? 'yyyy-MM-dd' : 'dd-MMM-yyyy'" class="boxCal" autoApply></Datepicker> -->
    </div>

    <div v-if="objType === 'fullSignature'" class="p-1">
      <fa icon="signature" size="lg" class="me-1" /> 
      <span>Full Signature</span>
    </div>

    <div v-if="objType === 'initial'" @click="openAttr()" class="p-1">
      <fa icon="pen-nib" size="lg" class="me-1" /> 
      <span>Initial</span> <span class="small"><i>(Appear in all pages)</i></span>
    </div>

    <!-- Temporary hide click to edit feature 
    <div v-if="title === 'Textbox' && editable[id]" class="p-1">
      <table>
        <tr>
          <td><fa icon="font" size="lg" class="me-1" /></td>
          <td><input v-if="editable[id]" type="text" v-model="label" class="form-control form-control-sm" style="margin: 0px 8px; width: 150px" @blur="toggleEdit(id)" /></td>
        </tr>
      </table>
    </div>

    <div v-if="title === 'Textbox' && !editable[id]" class="p-1">
      <fa icon="font" size="lg" class="me-1" />
      <span @click="toggleEdit(id)">{{ label }}</span>
    </div> -->

    <div class="px-2 position-absolute top-0 end-0" style="padding-top: 2px;" >
      <fa icon="times" @click="delBox(id)" style="cursor: pointer; padding-top: 1px"/>
    </div>
    <!-- <div class="text-primary mt-1">{{x}}, {{y}} : {{uiRatio}}</div> -->
    <!-- <div class="text-primary mt-1">{{x/uiRatio}}, {{y/uiRatio}}</div> -->
    <!-- <div class="text-primary"> id:box{{ id }} - {{x}}x{{y}} pg{{page}} sg{{signee}}<br>{{objType}} | {{boxClass}} | {{data}}</div> -->
    <!-- <div class="text-primary">{{data}}</div> -->
    <!-- <div class="text-primary">{{w}} x  {{h}}</div> -->
    <!-- <div class="text-primary"> {{boxClass}} {{isDragable}} {{typeof isDragable}}</div> -->
    <!-- <div class="text-primary small"> {{boxClass}} - {{id}}</div> -->
    <!-- <div class="small ps-3"> {{fontSize}}</div> -->

  </Vue3DraggableResizable>

</template>
 
<script>
import { ref, watch, onMounted } from 'vue'
import Vue3DraggableResizable from 'vue3-draggable-resizable'
// import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'
import Modal from '@/components/Modal.vue'
export default {
    props: [ 'data', 'boxClass', 'signee', 'isDragable', 'uiRatio', 'viewWidth'],
    components: { Vue3DraggableResizable, /* Datepicker, */ Modal },
    emits: ['getPosition', 'getBox', 'delBox'],
    setup (props, { emit }) {
      // console.info('dragbox data', JSON.stringify(props.data))

        const x = ref(props.data.position.x)
        const y = ref(props.data.position.y)

        const w = ref(200) // ref(props.data.w)
        const h = ref(32) // ref(props.data.h)
        const signee = ref(props.signee)
        const label = ref(props.data.label)
        const placeHolder = ref(props.data.placeholder)
        const mandatory = ref(props.data.isMandatory)
        const page = ref(props.data.position.page)
        const objType = ref(props.data.type)
        const fontFamily = ref(props.data.format.font)
        const fontSize = ref(props.data.format.size)
        const boxClass = ref(props.boxClass)
        const uiRatio = ref(props.uiRatio)
        const excPages = ref(props.data.excludedPages)
        const viewWidth = ref(props.viewWidth)

        if (objType.value === 'fullSignature' || objType.value === 'initial') {
          w.value = 250
          h.value = 150
        }
        
        if (fontFamily.value === undefined) {
          fontFamily.value = 'Helvetica'
        }

        if (fontSize.value === undefined) {
          // ORIG fontSize.value = 14
          fontSize.value = 14
        }

        // fontSize.value = fontSize.value * Math.floor(uiRatio.value)

        // console.info('fontSize', fontSize)
        // console.info('fontFamily', fontFamily)
        // console.info('DragBox def', props.data)

        const getPosition = ref(0)
        const id = ref(props.data.id)
        const editable = ref([])

        // const date = ref(new Date())
        const date = ref(label.value)
        const getBox = ref(null)

        const mdlBoxId = ref(null)

        watch([x, y, label, placeHolder, fontFamily, fontSize, mandatory, w, h, excPages], () => {
          console.info('DragBox - watch')
          // console.info('watch xy ', 'x:', x.value, 'y:', y.value, 'font:', props.data.mandatory)
          // console.info('watch xy data', JSON.stringify(props.data))
          emit('getPosition', id.value, x.value, y.value, signee.value, props.data.label, props.data.placeholder, props.data.isMandatory, page.value, objType.value, props.data.format.font, props.data.format.size, w.value, h.value, props.data.excludedPages)
        })

        const toggleEdit = (id) => {
          editable.value[id] = !(editable.value[id] === true ? true : false)
        }

        const delBox = (id) => {
            emit('del', id)
        }

        const openAttr = () => {
          console.info('DragBox - openAttr', id.value, props.data.format.size)
          // console.info('openAttr', objType.value)
          emit('getBox', id.value, x.value, y.value, signee.value, props.data.label, props.data.placeholder, props.data.isMandatory, page.value, objType.value, props.data.format.font, props.data.format.size, props.data.excludedPages)
        }

        const setDate = (value) => {
            date.value = value
            label.value = value
            console.info('setDate', value)
            console.info('setDate date', date.value)
            console.info('setDate lbl', date.value)
        }

        onMounted(() => {
          console.info('onMounted', viewWidth.value)
          if (viewWidth.value <= 500) {
              console.info('objType', objType.value)
              if (objType.value === 'fullSignature' || objType.value === 'initial') {

                  w.value = w.value * 0.24
                  h.value = h.value * 0.24

              } else {
                  w.value = w.value * 0.25
                  h.value = h.value * 0.25
              }

              
          }
        })

        return { 
          x, y, w, h, id, signee, label, placeHolder, mandatory, excPages, page, /* title,  */objType, fontFamily, fontSize, getPosition, toggleEdit, editable, delBox, date, setDate, getBox, boxClass,
          openAttr, mdlBoxId
          }
    }
}
</script>

<style>
</style>