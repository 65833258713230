<template>
    <div
        ref="RefModalSample"
        class="modal fade"
        :id="id"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> {{ title }}</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <slot></slot>
                <!-- <div class="modal-body"></div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                    <button type="button" class="btn" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-secondary">Save</button>
                </div> -->
            </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div>
</template>

<script>
// import { ref } from 'vue'
import vueFilePond from "vue-filepond"
import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css"
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
)

export default {
    props: ['id', 'title', 'boxId', 'type', 'boxClass'],
    components: { FilePond },
    data() {
        return {
            myFiles: []
        }
    },
    mounted() {
        const el = this.$refs["RefModalSample"]

        el.addEventListener("show.bs.modal", function (event) {
            // do something...
            // console.info('trigger show')
        })

        el.addEventListener("hidden.bs.modal", function (event) {
            // do something...
            console.info('trigger close')
        })
    },
    methods: {
        closeModal () {
            this.$emit('close')
        },
        handleFilePondInit (){
        console.info('FilePond has initialized')
        // example of instance method call on pond reference
        this.$refs.pond.getFiles()
      }
    }
}
</script>
// uploader
//img, pdf
// https://www.npmjs.com/package/vue-file-agent

// img only
// https://www.npmjs.com/package/vue-upload-component

// image only - flip, crop, preview
// https://www.npmjs.com/package/vue-filepond
// https://pqina.nl/filepond/#examples

<style>
</style>
